import { fork } from 'redux-saga/effects';

import { authSaga } from '../actions/auth';
import { usersSaga } from '../actions/users';
import { transactionsSaga } from '../actions/transactions';
import { sitesSaga } from '../actions/sites';

export default function* mainSaga() {
    yield fork(authSaga);
    yield fork(usersSaga);
    yield fork(transactionsSaga);
    yield fork(sitesSaga);
}
