import * as React from 'react';
import { Tag } from 'antd';
import { Status } from '../store/api/apiTypes';
import { TranslateTransactionStatus } from '../helpers';

interface TagTransactionStatusProps {
    value: Status;
}

const TagTransactionStatus: React.FC<TagTransactionStatusProps> = ({ value }) => {
    const tags: any = {};
    tags[Status.Pending] = {color: '#808080'};
    tags[Status.Paid] = {color: '#1ED143'};
    tags[Status.Cancelled] = {color: '#FF445A'};
    tags[Status.Abandonned] = {color: '#FF445A'};
    tags[Status.Processing] = {color: '#808080'};
    tags[Status.Rejected] = {color: '#FF445A'};
    tags[Status.InternalError] = {color: '#FF445A'};

    tags[Status.BadgeSent] = {color: '#1ED143'};
    tags[Status.BadgeNotSent] = {color: '#808080'};

    const color: string = (tags[value]) ? tags[value].color : '#808080';

    return (
        <Tag color={color}>{TranslateTransactionStatus(value)}</Tag>
    );
};

export default TagTransactionStatus;
