import { UserRole } from './../store/api/apiTypes';
import { User } from '../store/api/apiTypes';

export const hasRole = (user: User | undefined, role: UserRole) => {
    return !role || (user && user.role === role);
};

export default {
    hasRole,
};
