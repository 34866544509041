import React, { FC, useEffect } from 'react';
import { Drawer, Spin, Row, Col, Tag, Divider, Typography } from 'antd';
import { MainReducerState } from '../../store/reducers';
import { connect } from 'react-redux';
import { Transaction, Status, PSPName } from '../../store/api/apiTypes';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import Button, { ButtonProps } from 'antd/lib/button';
import genericMessages from '../../locale/genericMessages';
import {
    TransactionsState,
    details as transactionDetails,
    invoice as transactionsInvoice,
} from '../../store/actions/transactions';
import TagTransactionStatus from '../../components/TagTransactionStatus';
import Price from '../../components/Price';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

interface TransactionDrawerProps extends RouteProps {
    id?: Transaction['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    transactions: TransactionsState;
    getDetails: typeof transactionDetails.trigger;
    detailsReset: typeof transactionDetails.reset;
    getInvoice: typeof transactionsInvoice.trigger;
}

const TransactionDrawer: FC<TransactionDrawerProps> = ({
    id,
    isVisible,
    onClose,
    onSuccess,

    transactions,
    getDetails,
    detailsReset,
    getInvoice,
}) => {

    const transaction: Transaction | undefined = transactions.details.data;

    useEffect(() => {
        if (isVisible && id) {
            getDetails({id});
        } else {
            detailsReset();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
    };

    // ---------------------------------------
    // Invoices

    const invoice = () => {
        getInvoice({id});
    };

    return (
        <Drawer
            className="transaction-drawer"
            title={'Transaction' + ((transaction?.titleId) ? ' #' + transaction.titleId : '')}
            width={500}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={transactions.details.loading}>
                {transaction && (
                    <>
                        <div className="info-row">
                            <label><FormattedMessage {...genericMessages.paymentStatus} /></label>
                            <div className="value">
                                <TagTransactionStatus value={transaction.paymentStatus} />
                            </div>
                        </div>
                        <div className="info-row">
                            <label><FormattedMessage {...genericMessages.type} /></label>
                            <div className="value">
                                <FormattedMessage {...genericMessages.transactionsParkings} />
                            </div>
                        </div>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={11}>
                                <div className="info-row">
                                    <label> <FormattedMessage {...genericMessages.transactionDate} /> </label>
                                    <div className="value">{moment(transaction.createdAt).format('DD/MM/YYYY')}</div>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={13}>
                                <div className="info-row">
                                    <label><FormattedMessage {...genericMessages.transactionServerQueryTime} /></label>
                                    <div className="value">{moment(transaction.createdAt).format('HH:mm')}</div>
                                </div>
                            </Col>
                        </Row>
                        <div className="info-row">
                            <label><FormattedMessage {...genericMessages.parking} /></label>
                            <div className="value">{transaction.zoneName}</div>
                        </div>

                        <Row gutter={24}>
                            <Col className="gutter-row" span={24}>
                                <div className="info-row">
                                    <label>
                                        <FormattedMessage id="ticket.id" defaultMessage="ID du ticket" />
                                    </label>
                                    <div className="value">
                                        <Tag>{transaction.ticketId}</Tag>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="info-row">
                            <label><FormattedMessage id="parking.hours" defaultMessage="Heures de stationnement" /></label>
                            <div className="value">{moment(transaction.entryDateTime).format('DD/MM/YYYY - HH:mm')}  →  {moment(transaction.createdAt).format('DD/MM/YYYY - HH:mm')}</div>
                        </div>
                        <div className="info-row">
                            <label><FormattedMessage id="transaction.timeRange" defaultMessage=" Heure limite de validité de la transaction" /></label>
                            <div className="value">{moment(transaction.exitDateTime).format('DD/MM/YYYY - HH:mm')}</div>
                        </div>

                        <Divider />

                        <div className="mb-3">
                            <Typography.Title level={2} className="text-primary">
                                <FormattedMessage {...genericMessages.payment} />
                            </Typography.Title>
                        </div>

                        <div className="info-row">
                            <label><FormattedMessage {...genericMessages.subscriptionPaymentForm} /></label>
                            {transaction.psp === PSPName.payline && (<div className="value">Payline</div>)}
                            {transaction.psp === PSPName.nmi && (<div className="value">NMI</div>)}
                        </div>

                        {transaction.paymentToken && transaction.psp === PSPName.payline && (
                            <Row gutter={24}>
                                <Col className="gutter-row" span={24}>
                                    <div className="info-row">
                                        <label><FormattedMessage {...genericMessages.paylineId} /> </label>
                                        <div className="value"><small>{transaction.paymentToken}</small></div>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        {transaction.paymentToken && transaction.psp === PSPName.nmi && (
                            <Row gutter={24}>
                                <Col className="gutter-row" span={24}>
                                    <div className="info-row">
                                        <label><FormattedMessage {...genericMessages.nmmiId} /></label>
                                        <div className="value"><small>{transaction.paymentToken}</small></div>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        {(transaction.paylineTransactionResult && transaction.paylineTransactionResult?.longMessage) && (
                            <div className="info-row">
                                <label><FormattedMessage {...genericMessages.paylineStatus} /></label>
                                <div className="value"><Tag>{transaction.paylineTransactionResult.longMessage}</Tag></div>
                            </div>
                        )}

                        {(transaction.nmiTransactionResult && transaction.nmiTransactionResult?.message) && (
                            <div className="info-row">
                                <label><FormattedMessage {...genericMessages.nmiStatus} /></label>
                                <div className="value"><Tag>{transaction.nmiTransactionResult.message}</Tag></div>
                            </div>
                        )}

                        {(transaction.totalDueAmount > 0) && (
                            <div className="info-row">
                                <label><FormattedMessage {...genericMessages.amount} /></label>
                                <div className="value prices-table">
                                    {transaction.dutyFreeAmount && (
                                        <div className="price-row">
                                            <span className="price-label"><FormattedMessage {...genericMessages.priceTotalExcludingTaxes} /></span>
                                            <span className="price-value">
                                                <Price value={transaction.dutyFreeAmount} currency={transaction.currencyIso} currencyCentsDigits={transaction.currencyCentsDigits} />
                                            </span>
                                        </div>
                                    )}
                                    {transaction.duties.map((tax, index) => (
                                        <div className="price-row" key={'tax-row-' + index}>
                                            <span className="price-label">{tax.name} ({tax.rate}%)</span>
                                            <span className="price-value"><Price value={tax.amount} /></span>
                                        </div>
                                    ))}
                                    <div className="price-row">
                                        <span className="price-label"><strong><FormattedMessage {...genericMessages.priceTotalIncludingTaxes} /></strong></span>
                                        <span className="price-value">
                                            <strong>
                                                <Price value={transaction.totalDueAmount} currency={transaction.currencyIso} currencyCentsDigits={transaction.currencyCentsDigits} />
                                            </strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {transaction.paymentStatus === Status.Paid && (
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                block
                                onClick={invoice}
                            >
                                Télécharger le reçu
                            </Button>
                        )}
                    </>
                )}
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    transactions: state.transactions,
});

export default connect(
    mapStateToProps,
    {
        getDetails: transactionDetails.trigger,
        detailsReset: transactionDetails.reset,
        getInvoice: transactionsInvoice.trigger,
    },
)(TransactionDrawer);
