import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Layout, Form, Input, Button, Alert } from 'antd';
import { AuthState, login, getAuthState } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';
import Seo from '../../components/Seo';
import { Breakpoint } from 'react-socks';
import genericMessage from '../../locale/genericMessages';
import formMessage from '../../locale/formMessages';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    theme,
} from '../../store/actions/theme';

import '../../assets/styles/Login.less';

import logo from '../../assets/images/logo.png';

interface LoginProps extends RouteComponentProps {
    onLogin: typeof login.trigger;
    onLoginReset: typeof login.reset;
    authState: AuthState;
    setTheme: typeof theme.actions.set;
    resetTheme: typeof theme.actions.reset;
}

const Login: FC<LoginProps> = ({
    authState,
    onLogin,
    onLoginReset,
    setTheme,
    resetTheme ,
}) => {

    useEffect(() => {
        onLoginReset();
        resetTheme();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        onLogin(values);
    };
    const {formatMessage} = useIntl();
    if (authState.isConnected) {

        if (authState.user?.sites.length === 1) {
            if (authState.user?.sites[0].themeColor) {
                setTheme({name: 'color', value: authState.user?.sites[0].themeColor});
            }
            if (authState.user?.sites[0].logoImage) {
                setTheme({name: 'logo', value: authState.user?.sites[0].logoImage});
            }
        }

        return <Redirect to="/" />;
    }

    let error = authState.error ? formatMessage(genericMessage.error_try_again) : null;

    if (authState.error && authState.error.status) {
        switch (authState.error.status) {
            case 401:
                error = formatMessage(genericMessage.errorUnauthorised);
                break;

            case 403:
                error = formatMessage(formMessage.password_invalid);
                break;

            case 404:
                error = formatMessage(genericMessage.errorUnknownUser);
                break;

            default:
                error = formatMessage(genericMessage.error_try_again);
                break;
        }
    }

    return (
        <Layout id="login-layout">
            <Seo title="Orbility - Connexion" />
            <Breakpoint lg up className="login-background" />
            <div id="login-section">
                <Form
                    className="login-form"
                    onFinish={onFormValidSubmit}
                    hideRequiredMark
                >
                    <img className="logo" src={logo} alt="logo" />

                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: formatMessage(formMessage.email_error)}]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            size="large"
                            placeholder={formatMessage(formMessage.username_label)}
                        />
                    </Form.Item>

                    <Form.Item
                        className="password-row"
                        name="password"
                        rules={[{ required: true, message: formatMessage(formMessage.password_error) }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            size="large"
                            placeholder={formatMessage(formMessage.password_label)}
                        />
                    </Form.Item>

                    <Form.Item>
                        {error ? (
                            <div className="login-error-message">
                                <Alert
                                    type="error"
                                    message={error}
                                    showIcon
                                />
                            </div>
                        ) : null}

                        <Button
                            type="primary"
                            shape="round"
                            size="large"
                            block
                            htmlType="submit"
                            loading={authState.loading}
                        >
                           <FormattedMessage id="action_connect" defaultMessage="Connexion" />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        onLogin: login.trigger,
        onLoginReset: login.reset,
        setTheme: theme.actions.set,
        resetTheme: theme.actions.reset,
    },
)(Login);
