import { Button, Drawer, Form, Input, Select, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { LanguagesList, Page, SupportedLanguage, User } from '../store/api/apiTypes';
import Flag from './Flag';
import ReactQuill from 'react-quill';
import { FormProps } from 'antd/lib/form';

import { FormattedMessage, useIntl } from 'react-intl';
import genericMessages from '../locale/genericMessages';

const { TabPane } = Tabs;

const toolbarFormat = {
    toolbar: [
        [
            { header: [1, 2, 3] },
        ],
        [
            { font: [] },
            { size: [] },
            { color: [] },
        ],
        ['bold', 'italic', 'underline'],
        ['image', 'link'],
    ],
};

interface PendingRequestEmailDrawerProps {
    value?: any;
    initialValue?: any;
    onChange?: (value: Page) => void;
    label: string;
    buttonElement?: (onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => React.ReactNode;
    buttonType: 'icon' | 'button';
    users: User[];
}

const PendingRequestEmailDrawer: React.FC<PendingRequestEmailDrawerProps> = ({ onChange, value, initialValue, label, buttonElement, buttonType, users }) => {

    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ tabLang, setTabLang ] = useState<SupportedLanguage>(SupportedLanguage.fr);
    const [form] = Form.useForm();
    const {formatMessage} = useIntl();
    const requiredRule = { required: true, message: formatMessage(genericMessages.mandatoryField) };

    useEffect(() => {
        form.setFieldsValue(initialValue);
    }, [initialValue, form]);

    const openDrawer = () => {
        setShowDrawer(true);
        setTabLang(SupportedLanguage.fr);
    };

    const onDrawerClose = () => {
        setShowDrawer(false);
    };

    const tabChange = (val: string) => {
        setTabLang((val as SupportedLanguage));
    };

    const onFormFinish: FormProps['onFinish'] = (values: any) => {
        form.validateFields().then(() => {
            if (onChange) {
                onChange(values);
                setShowDrawer(false);
            }
        });
    };

    const options = users.map((user) => {
        return({
            id: user.id,
            value: user.email,
        });
    });

    return (
        <>
            { buttonElement && buttonType === 'icon' && (
                <div className="flex-between">
                    <span>{label}</span>
                    {buttonElement(openDrawer)}
                </div>
            )}
            { buttonElement && buttonType === 'button' && (
                    buttonElement(openDrawer)
            )}

            <Drawer
                className="page-drawer"
                title={formatMessage({id: 'page.edit', defaultMessage: 'Editer la page'})}
                width={750}
                onClose={onDrawerClose}
                visible={showDrawer}
            >
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Tabs className="tab-lang" animated={false} onChange={tabChange}>
                        { LanguagesList.map((language: SupportedLanguage) => (
                            <TabPane tab={<Flag language={language} />} key={'content-' + language}>
                                <Form.Item
                                    label={formatMessage({id: 'page.title', defaultMessage: 'Objet de l\'email'})}
                                    name={['title', language]}
                                    className="item-layout-vertical"
                                    initialValue={initialValue && initialValue.title ? initialValue.title : ''}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                >
                                    <Input size="large" placeholder="Saisir un titre" />
                                </Form.Item>
                                <Form.Item
                                    label={formatMessage({id: 'page.recipient', defaultMessage: 'Destinataire de l\'email'})}
                                    name={['recipient']}
                                    className="item-layout-vertical"
                                    initialValue={initialValue && initialValue.recipient ? initialValue.recipient : []}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        options={options}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={formatMessage({id: 'page.content', defaultMessage: 'texte à afficher sous le bouton de confirmation'})}
                                    name={['content', language]}
                                    className="item-layout-vertical"
                                    initialValue={initialValue && initialValue.content ? initialValue.content : initialValue}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                >
                                    <ReactQuill
                                        placeholder=""
                                        modules={toolbarFormat}
                                        theme="snow"
                                    />
                                </Form.Item>
                            </TabPane>
                        ))}
                    </Tabs>

                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                        >
                            <FormattedMessage id="page.update" defaultMessage="Mettre à jour"/>
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default PendingRequestEmailDrawer;
