import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react';

interface UploadFileProps {
    value?: any;
    onChange?: (value: RcFile) => void;
}

const UploadFile: React.FC<UploadFileProps> = ({ onChange, value }) => {

    const [ file, setFile ] = useState<RcFile>();
    const [ showFileUpload, setShowFileUpload ] = useState(true);

    // ---------------------------------------
    // Prepare thumbnail upload file

    const prepareLogoUpload: DraggerProps['beforeUpload'] = (newFile) => {
        setFile(newFile);
        if (onChange) {
            onChange(newFile);
        }
        return false;
    };

    const onFileRemove: DraggerProps['onRemove'] = () => {
        setFile(undefined);
        setShowFileUpload(true);
    };

    useEffect(() => {
        setShowFileUpload(!value);
    }, [value]);

    return (
        <>
            {(showFileUpload && !file) ? (
                <Dragger
                    beforeUpload={prepareLogoUpload}
                    multiple={false}
                    listType="picture"
                    showUploadList={false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Cliquez-ici ou déposez un fichier</p>
                    <p className="ant-upload-hint">
                        Fichier au format jpg ou png, taille maximum 1 MB.
                    </p>
                </Dragger>
            ) : (
                <Upload
                    onRemove={onFileRemove}
                    listType="picture"
                    fileList={
                        file ? [file] : (
                        value ?
                            [
                                {
                                    uid: '0',
                                    name: value,
                                    status: 'done',
                                    url: value,
                                    thumbUrl: value,
                                    size: 0,
                                    type: 'test',
                                },
                            ] : []
                        )
                    }
                />
            )}
        </>
    );
};

export default UploadFile;
