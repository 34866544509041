import { SearchPaginationQuery } from './';
import api, { cleanPayload } from './_client';
import { Transaction, TransactionValidationStatus } from './apiTypes';

export interface TransactionDetailsPayload {
    id: string;
}

export interface TransactionValidationPayload {
    id: string;
    validationStatus: TransactionValidationStatus;
    validationComment: string;
}

export type TransactionListPayload = SearchPaginationQuery;

export const details = (payload: TransactionDetailsPayload) => api.get(`/cms/transactions/${payload.id}`);

export const list = (payload: TransactionListPayload = {}) => {
    return api.get(`/cms/transactions`, {params: cleanPayload(payload)});
};

export const exportTransactions = (payload: TransactionListPayload) => {
    return api.get(`/cms/transactions/export`, {responseType: 'blob', params: payload});
};

export const invoice = (payload: Transaction) => {
    return api.get(`/transactions/receipt/${payload.id}`, {responseType: 'blob'});
};

export const deliveryForm = (payload: Transaction) => {
    return api.get(`/cms/delivery/${payload.id}`, {responseType: 'blob'});
};

export const validate = (payload: TransactionValidationPayload) => {
    return api.put(`/cms/transactions/${payload.id}/validate`, {
        validationStatus: payload.validationStatus,
        validationComment: payload.validationComment,
    });
};
