import React, { FC } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Result } from 'antd';
import { ResultProps } from 'antd/lib/result';
import genericMessages from '../../locale/genericMessages';
import Seo from '../../components/Seo';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import { useIntl } from 'react-intl';

const ErrorPage: FC = () => {
    const {formatMessage} = useIntl();
    const { 0: param } = useParams();
    const statusCode = parseInt(param?.substring(1) || '', 10);
    let status: ResultProps['status'] = 'error';
    let title: ResultProps['title'] = formatMessage(genericMessages.error);
    let subTitle: ResultProps['subTitle'] = formatMessage(genericMessages.error_try_again);

    if (statusCode === 404 || statusCode === 400) {
        status = '404';
        title = statusCode;
        subTitle = statusCode === 404 ?
        formatMessage({
            id: 'error.missing_path', // obligatoire et unique
            defaultMessage:  'Nous sommes désolés, la page que vous cherchez n\'existe pas.',
        })
            :
            formatMessage({
                id: 'error.invalid_info', // obligatoire et unique
                defaultMessage:  'Nous sommes désolés, les informations envoyées ne sont pas valides.',
            });
    } else if (statusCode === 403 || statusCode === 401) {
        status = '403';
        title = statusCode;
        subTitle = statusCode === 403 ?
        formatMessage({
            id: 'error.forbidden_ressource', // obligatoire et unique
            defaultMessage:  'Nous sommes désolés, vous n\'êtes pas autorisé à accéder à cette ressource.',
        })
            :
            formatMessage({
                id: 'error.authenfication_required', // obligatoire et unique
                defaultMessage:  'Nous sommes désolés, vous devez être authentifié pour accéder à cette ressource.',
            });
    } else if (`${statusCode}`.length === 3 && `${statusCode}`.startsWith('5')) {
        status = 500;
        title = `${statusCode}`;
    } else {
        return <Redirect to={getRoute(RoutePathName.notFound)} />;
    }

    return (
        <>
            <Seo title={`${statusCode}`} />
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
                extra={(
                    <ButtonLink
                        to={getRoute(RoutePathName.transactions)}
                        type="primary"
                    >
                        Retour à l'accueil
                    </ButtonLink>
                )}
            />
        </>
    );
};

export default ErrorPage;
