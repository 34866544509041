import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { FormattedMessage, useIntl } from 'react-intl';
import genericMessages from '../../locale/genericMessages';
import {
    Table,
    Button,
    Badge,
    Popconfirm,
} from 'antd';

import { Site } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import Search from 'antd/lib/input/Search';
import SiteDrawer from './SiteDrawer';
import {
    SitesState,
    list as sitesList,
    remove as sitesRemove,
    update as siteUpdate,
} from '../../store/actions/sites';

import { FilterQuery } from '../../store/api';
import { PlusOutlined, DeleteOutlined, EditOutlined, FileSearchOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Seo from '../../components/Seo';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import '../../assets/styles/SitesList.less';

const rowKey = (item: Site) => `${item.id}`;

interface SitesListProps {
    sites: SitesState;
    getList: typeof sitesList.trigger;
    remove: typeof sitesRemove.trigger;
    resetRemove: typeof sitesRemove.reset;
    update: typeof siteUpdate.trigger;
}

const SitesList: FC<SitesListProps> = ({
    sites,
    getList,
    remove,
    resetRemove,
    update,
}) => {

    const itemsPerPage: number = 20;
    const {formatMessage} = useIntl();
    const history = useHistory();
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    // const [ search, setSearch ] = useState<string>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useState<{}>();

    useEffect(() => {
        setLastSearchParams({
            ...lastSearchParams,
            page: 0,
            pageSize: itemsPerPage,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastSearchParams) { getList({...lastSearchParams}); }
    }, [lastSearchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (sites.remove.success) {
            resetRemove();
            getList({...lastSearchParams});
        }
    }, [sites.remove.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    const onTableChange: TableProps<Site>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const filters: FilterQuery['filters'] = [];

        if (tableFilters.role && tableFilters.role.length > 0) {
            filters.push({
                name: 'role',
                value: tableFilters.role,
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters,
        };

        setLastSearchParams(newSearchParams);
    };

    const showTransactions = (site: Site) => {
        history.push(getRoute(RoutePathName.transactions, {}, {site: site.name}));
    };

    // ---------------------------------------
    // Drawer management

    const edit = (id: string) => {
        setSelectedId(id);
        setIsDrawerVisible(true);
    };

    const add = () => {
        setSelectedId(undefined);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList({...lastSearchParams});
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Disable / Enable / Delete site

    const disable = (site: Site) => {
        update({
            id: site.id,
            enabled: false,
        });
    };

    const enable = (site: Site) => {
        update({
            id: site.id,
            enabled: true,
        });
    };

    const removeSite = (site: Site) => {
        remove({id: site.id});
    };

    // ---------------------------------------
    // Table columns

    const columns: Array<ColumnProps<Site>> = [
        {
            dataIndex: 'siteId',
            title: formatMessage({  id: 'site.id',  defaultMessage: 'ID' }),
        },
        {
            dataIndex: 'siteCode',
            title: formatMessage({  id: 'site.code',  defaultMessage: 'Code'  }),
        },
        {
            dataIndex: 'name',
            title: formatMessage(genericMessages.siteCommercialName),
            sorter: true,
            ellipsis: true,
        },
        {
            dataIndex: 'phone',
            title: formatMessage(genericMessages.phone),
        },
        {
            dataIndex: 'apimKey',
            title: formatMessage(genericMessages.siteToken),
            render: (apimKey) => {
                return apimKey.substring(0, 4) + '************';
            },
        },
        {
            title: formatMessage(genericMessages.actions),
            key: 'actions',
            fixed: 'right',
            width: 190,
            render: (text, record) => (
                <>
                    <Button
                        icon={<FileSearchOutlined />}
                        onClick={showTransactions.bind(null, record)}
                        shape="circle"
                        title={formatMessage(genericMessages.siteShowTransactions)}
                    />
                    &nbsp;&nbsp;
                    <Button
                        icon={<EditOutlined />}
                        onClick={edit.bind(null, record.id)}
                        shape="circle"
                        title={formatMessage(genericMessages.edit)}
                    />
                    &nbsp;&nbsp;
                    {record.enabled ? (
                        <Popconfirm
                            title={formatMessage(genericMessages.siteConfirmDisable)}
                            onConfirm={disable.bind(null, record)}
                            okText={formatMessage(genericMessages.validate)}
                            cancelText={formatMessage(genericMessages.cancel)}
                            placement="topRight"
                        >
                            <Button
                                icon={<StopOutlined />}
                                shape="circle"
                                title={formatMessage(genericMessages.disable)}
                            />
                        </Popconfirm>
                    ) : (
                        <>
                        <Popconfirm
                            title={formatMessage(genericMessages.siteConfirmEnable)}
                            onConfirm={enable.bind(null, record)}
                            okText={formatMessage(genericMessages.validate)}
                            cancelText={formatMessage(genericMessages.cancel)}
                            placement="topRight"
                        >
                            <Button
                                icon={<CheckCircleOutlined  />}
                                shape="circle"
                                title={formatMessage(genericMessages.enable)}
                            />
                        </Popconfirm>
                            &nbsp;&nbsp;
                            <Popconfirm
                                title={formatMessage(genericMessages.confirmDelete)}
                                onConfirm={removeSite.bind(null, record)}
                                okText={formatMessage(genericMessages.validate)}
                                cancelText={formatMessage(genericMessages.cancel)}
                                placement="topRight"
                            >
                                <Button
                                    icon={<DeleteOutlined  />}
                                    danger
                                    shape="circle"
                                    title={formatMessage(genericMessages.delete)}
                                />
                            </Popconfirm>
                        </>
                    )}
                </>
            ),
        },

    ];

    return (
        <>
            <Seo title="Sites" />

            <div className="page-header">
                <h1 className="page-title">
                    <FormattedMessage {...genericMessages.headerSites}/> <Badge count={sites.list.data.totalCount} overflowCount={100000} />
                </h1>

                <Button
                    type="primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={add}
                >
                    <FormattedMessage id="site.create" defaultMessage="Créer un site" />
                </Button>
            </div>

            <Search
                className="page-search"
                placeholder={formatMessage(genericMessages.siteSearch)}
                loading={sites.list.loading}
                onSearch={onSearch}
                allowClear
                size="large"
            />

            {sites.list ? (
                <Table<Site>
                    // tslint:disable-next-line: jsx-no-lambda
                    rowClassName={(record) => !record.enabled ? 'table-row-dark' : ''}
                    rowKey={rowKey}
                    columns={columns}
                    loading={sites.list.loading}
                    dataSource={sites.list.data.items}
                    pagination={{
                        total: sites.list.data.totalCount,
                        current: sites.list.data.page + 1,
                        pageSize: sites.list.data.pageSize,
                    }}
                    onChange={onTableChange}
                    scroll={{ x: 1000 }}
                />
            ) : undefined}

            <SiteDrawer
                id={selectedId}
                isVisible={isDrawerVisible}
                onClose={onDrawserClose}
                onSuccess={onDrawerSuccess}
            />
        </>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    sites: state.sites,
});

export default connect(
    mapStateToProps,
    {
        getList: sitesList.trigger,
        remove: sitesRemove.trigger,
        resetRemove: sitesRemove.reset,
        update: siteUpdate.trigger,
    },
)(SitesList);
