import React, { useState, FC } from 'react';
import { PasswordProps } from 'antd/lib/input/Password';
import validatePasswordRules, { PasswordIssueReason } from '../../helpers/passwords';
import { Input as AntInput } from 'antd';

import BasicList from '../BasicList';
import { classNames } from '../../helpers';
import { CheckOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import formMessages from '../../locale/formMessages';

interface PasswordInputProps extends PasswordProps {
    value?: string;
    withInstruction?: boolean;
}

const InputPassword: FC<PasswordInputProps> = ({ value, withInstruction, onChange, ...props }) => {
    const placeholder: string | undefined = props.placeholder;
    delete props.placeholder;

    const [val, setVal] = useState(value ?? '');
    const [errors, setErrors] = useState<ReturnType<typeof validatePasswordRules>>();
    const onChangeInput: PasswordProps['onChange'] = (e) => {
        const inputValue = e.target.value;
        setVal(inputValue);
        setErrors(validatePasswordRules(inputValue));
        if (typeof onChange === 'function') {
            onChange(e);
        }
    };

    return (
        <>
            <div className={'input-float-label ' + (!value ? 'empty' : 'not-empty')}>
                <label className="float-label">{placeholder}</label>
                <AntInput.Password {...props} onChange={onChangeInput} />
            </div>
            {withInstruction && (
                <div className="password-instructions">
                    <strong className="text-sm">
                        <FormattedMessage {...formMessages.password_instructions_title} />
                    </strong>
                    <BasicList className="password-input-instructions">
                        <li>
                            <div>
                                <CheckOutlined
                                    className={classNames(
                                        !!val &&
                                            !errors?.issues.find(
                                                (issue) =>
                                                    issue.reason === PasswordIssueReason.minimumLength ||
                                                    issue.reason === PasswordIssueReason.maximumLength,
                                            ) &&
                                            'is-valid',
                                    )}
                                />
                                <FormattedMessage {...formMessages.password_instructions_1} />
                            </div>
                            <div>
                                <CheckOutlined
                                    className={classNames(
                                        !!val &&
                                            !errors?.issues.find(
                                                (issue) => issue.reason === PasswordIssueReason.requireNumber,
                                            ) &&
                                            'is-valid',
                                    )}
                                />
                                <FormattedMessage {...formMessages.password_instructions_2} />
                            </div>
                        </li>
                        <li>
                            <div>
                                <CheckOutlined
                                    className={classNames(
                                        !!val &&
                                            !errors?.issues.find(
                                                (issue) => issue.reason === PasswordIssueReason.requireCapital,
                                            ) &&
                                            'is-valid',
                                    )}
                                />
                                <FormattedMessage {...formMessages.password_instructions_3} />
                            </div>
                            <div>
                                <CheckOutlined
                                    className={classNames(
                                        !!val &&
                                            !errors?.issues.find(
                                                (issue) => issue.reason === PasswordIssueReason.requireSpecial,
                                            ) &&
                                            'is-valid',
                                    )}
                                />
                                <FormattedMessage {...formMessages.password_instructions_4} />
                            </div>
                        </li>
                    </BasicList>
                </div>
            )}
        </>
    );
};

export default InputPassword;
