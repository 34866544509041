import * as React from 'react';
import { Address } from '../store/api/apiTypes';

interface AddressProps {
    address ?: Address;
}

const AddressRow: React.FC<AddressProps> = ({ address }) => {
    return address ? (
        <div className="address">
            {address.companyName && (
                <div>
                    {address.companyName}
                </div>
            )}
            {address.firstName && (
                <div>
                    {address.firstName} {address.lastName}
                </div>
            )}
            {address.street} {address.complement}, {address.zipCode} {address.city}, {address.country}
        </div>
    ) : null;
};

export default AddressRow;
