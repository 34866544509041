import * as React from 'react';

interface PriceProps {
    value ?: number;
    currency ?: string;
    currencyCentsDigits ?: number;
}

const Price: React.FC<PriceProps> = ({ value, currency, currencyCentsDigits }) => {
    return value ? (
        <div className="price-tag">
            {
                Intl.NumberFormat('fr', {
                    style: 'currency',
                    currency: currency?.trim(),
                }).format(value / Math.pow(10, currencyCentsDigits || 2))
            }
        </div>
    ) : null;
};

Price.defaultProps = {
    currency: 'EUR',
    currencyCentsDigits: 2,
};

export default Price;
