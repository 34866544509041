import { Cookie, CookieSetOptions } from 'universal-cookie';
import ReactGA from 'react-ga';

import { CookieNames } from '../store/actions/cookies';

import { addYear, hasOwnProp } from '.';
import constants from '../config/constants';

export type SetCookie = (name: string, value: Cookie, options?: CookieSetOptions) => void;
export type RemoveCookie = (name: string, options?: CookieSetOptions) => void;

const googleAnalyticsCookies = ['__utmz', '__utmt', '__utmc', '__utmb', '__utma', '_gat', '_gid', '_ga'];

export const setCookiesOptions: CookieSetOptions = {
    path: '/',
    expires: addYear(new Date()),
};

export const removeCookiesOptions: CookieSetOptions = {
    path: '/',
};

export const acceptAllCookies = (setCookie: SetCookie, pathname: string) => {
    setCookie(CookieNames.HasMadeChoice, 1, setCookiesOptions);
    setCookie(CookieNames.GA, 1, setCookiesOptions);

    addGoogleAnalytics(pathname);
};

const removeGoogleAnalyticsScript = () => {
    const script = [...document.querySelectorAll('script')].find((el) =>
        el.src.includes('google-analytics'),
    );

    if (script) {
        script.parentNode?.removeChild(script);
    }
};

export const removeGoogleAnalytics = (removeCookie: RemoveCookie) => {
    removeGoogleAnalyticsScript();
    delete window.ga;

    googleAnalyticsCookies.forEach((gaCookie) => {
        removeCookie(gaCookie, removeCookiesOptions);
    });
};

export const addGoogleAnalytics = (pathname: string) => {
    if (!hasOwnProp(window, 'ga')) {
        loadGoogleAnalytics(constants.GOOGLE_ANALYTICS).then(() => {
            ReactGA.initialize(constants.GOOGLE_ANALYTICS, {
                standardImplementation: true,
            });
            ReactGA.pageview(pathname);
        });
    }
};

export const refuseAllCookies = (setCookie: SetCookie, removeCookie: RemoveCookie) => {
    setCookie(CookieNames.HasMadeChoice, 1, setCookiesOptions);
    setCookie(CookieNames.GA, 0, setCookiesOptions);

    removeGoogleAnalytics(removeCookie);
};

// npm i -D @types/google.analytics &&
declare global {
    interface Window { GoogleAnalyticsObject: any; }
}

/* eslint @typescript-eslint/no-unused-expressions: 0 */
/* eslint no-sequences: 0 */
/* tslint:disable ban-comma-operator only-arrow-functions */
export const loadGoogleAnalytics = (trackingID: string) =>
    new Promise((resolve) => {
        ((i, s, o: 'script', g, r: 'ga', a?: HTMLScriptElement, m?: Element) => {
            i.GoogleAnalyticsObject = r;
            (i[r] = i[r] || function() {
                (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date().getTime());
            (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
            a.async = true;
            a.src = g;
            a.addEventListener('load', resolve);
            m.parentNode?.insertBefore(a, m);
        })(
            window,
            document,
            'script',
            '//www.google-analytics.com/analytics.js',
            'ga',
        );
        window.ga('create', trackingID, 'auto');
        window.ga('send', 'pageview');
    });
