import { useIntl } from 'react-intl';
import { BadgeType, Civility, ServiceType, UserType, PSPName } from '../store/api/apiTypes';
import genericMessages from '../locale/genericMessages';

export const TranslateCivility = (value: Civility) => {
    const {formatMessage} = useIntl();
    switch (value) {
        case Civility.missus: return formatMessage(genericMessages.civilityMissus);
        case Civility.mister: return formatMessage(genericMessages.civilityMister);
        case Civility.ms: return formatMessage(genericMessages.civilityMs);
        case Civility.miss: return formatMessage(genericMessages.civilityMiss);
        case Civility.other: return formatMessage(genericMessages.civilityOther);
    }
};

export const TranslateBadgeType = (value: BadgeType) => {
    const {formatMessage} = useIntl();
    switch (value) {
        case BadgeType.dematerializedAndPhysical: return formatMessage(genericMessages.badgeTypeDematerializedAndPhysical);
        case BadgeType.physical: return formatMessage(genericMessages.badgeTypePhysical);
        case BadgeType.dematerialized: return formatMessage(genericMessages.badgeTypeDematerialized);
    }
};

export const TranslateUserType = (value: UserType) => {
    const {formatMessage} = useIntl();
    switch (value) {
        case UserType.company: return formatMessage(genericMessages.userTypeCompany);
        case UserType.individual: return formatMessage(genericMessages.userTypeIndividual);
    }
};

export const TranslateServiceType = (value: ServiceType) => {
    const {formatMessage} = useIntl();
    switch (value) {
        case ServiceType.payExt: return formatMessage(genericMessages.serviceTypePayExt);
        case ServiceType.ppo: return formatMessage(genericMessages.serviceTypePpo);
    }
};

export const TranslatePspType = (value: PSPName) => {
    const {formatMessage} = useIntl();
    switch (value) {
        case PSPName.payline: return formatMessage(genericMessages.pspNamePayline);
        case PSPName.nmi: return formatMessage(genericMessages.pspNameNmi);
        case PSPName.advam: return formatMessage(genericMessages.pspNameAdvam);
        case PSPName.globalPayment: return formatMessage(genericMessages.pspNameGlobalPayment);
    }
};
