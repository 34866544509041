import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Divider, Drawer, Row, Space, Spin, Tag, Typography } from 'antd';
import Button, { ButtonProps } from 'antd/lib/button';
import { DrawerProps } from 'antd/lib/drawer';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';
import AddressRow from '../../components/AddressRow';
import Price from '../../components/Price';
import TagTransactionStatus from '../../components/TagTransactionStatus';
import TagTransactionValidationStatus from '../../components/TagTransactionValidationStatus';
import { TranslateBadgeType, TranslateUserType } from '../../helpers/translate';
import { deliveryForm as transactionsDeliveryForm, details as transactionDetails, invoice as transactionsInvoice, TransactionsState } from '../../store/actions/transactions';
import { Status, Title, Transaction, PSPName, TransactionValidationStatus } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import UserRow from './UserRow';
import { FormattedMessage, useIntl } from 'react-intl';
import genericMessages from '../../locale/genericMessages';

import '../../assets/styles/Subscription.less';

interface SubscriptionDrawerProps extends RouteProps {
    id?: Transaction['id'];
    isVisible: boolean;
    onClose: (refresh?: boolean) => void;
    onSuccess?: () => void;
    setValidationData: (transactionId: string, validationAccepted: boolean) => void;

    transactions: TransactionsState;
    getDetails: typeof transactionDetails.trigger;
    detailsReset: typeof transactionDetails.reset;
    getInvoice: typeof transactionsInvoice.trigger;
    getDeliveryForm: typeof transactionsDeliveryForm.trigger;
}

const SubscriptionDrawer: FC<SubscriptionDrawerProps> = ({
    id,
    isVisible,
    onClose,
    onSuccess,
    setValidationData,

    transactions,
    getDetails,
    detailsReset,
    getInvoice,
    getDeliveryForm,
}) => {

    const transaction: Transaction | undefined = transactions.details.data;
    const title: Title | undefined = (transaction?.titles) ? transaction.titles[0] : undefined;
    const [listNeedsRefresh, setListNeedsRefresh] = useState(false);

    useEffect(() => {
        if (isVisible && id) {
            getDetails({ id });
            setListNeedsRefresh(false);
        } else {
            detailsReset();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose(listNeedsRefresh);
    };

    // ---------------------------------------
    // Invoices

    const invoice = () => {
        getInvoice({ id });
    };

    const deliveryForm = () => {
        getDeliveryForm({ id });
    };

    useEffect(() => {
        if (transactions.deliveryForm.success && transactions.deliveryForm) {
            getDetails({ id });
            setListNeedsRefresh(true);
        }
    }, [transactions.deliveryForm, getDetails, id]);

    const handleTransactionValidationStatus = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (transaction?.id) {
            const accepted: boolean = parseInt(event.currentTarget.value, 10) === 1;
            setValidationData(transaction.id, accepted);
        }
    };

    const {formatMessage} = useIntl();

    return (
        <Drawer
            className="transaction-drawer"
            title={formatMessage(genericMessages.transaction) + ((transaction?.titleId) ? ' #' + transaction.titleId : '')}
            width={500}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={transactions.details.loading}>
                {transaction && (
                    <>
                        {transaction.validationStatus === TransactionValidationStatus.pending && (
                            <Row className="subscription-validation" style={{ backgroundColor: '#F1F2F4' }}>
                                <Col className="gutter-row" span={19}>
                                    <Typography.Text strong={true} style={{ fontSize: '18px', marginLeft: '1em' }}>
                                        <FormattedMessage
                                            id="transaction.ask_confirm"
                                            defaultMessage="Souhaitez vous valider la transaction ?"
                                        />
                                    </Typography.Text>
                                </Col>
                                <Col className="gutter-row">
                                    <Space>
                                        <Button className="green" shape="circle" icon={<CheckOutlined />} value={1} onClick={handleTransactionValidationStatus} />
                                        <Button className="red" shape="circle" icon={<CloseOutlined />} value={0} onClick={handleTransactionValidationStatus} />
                                    </Space>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={16}>
                            <Col className="gutter-row" span={11}>
                                <div className="info-row">
                                    <label>
                                        <FormattedMessage
                                            id="transaction.status"
                                            defaultMessage="Statut de la transaction"
                                        />
                                    </label>
                                    <div className="value">
                                        <TagTransactionValidationStatus value={transaction.validationStatus} />
                                    </div>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={13}>
                                <div className="info-row">
                                    <label> <FormattedMessage {...genericMessages.paymentStatus} />
                                    </label>
                                    <div className="value">
                                        <TagTransactionStatus value={transaction.paymentStatus} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="info-row">
                            <label><FormattedMessage {...genericMessages.type} /></label>
                            <div className="value">
                                <FormattedMessage
                                    id="subscription"
                                    defaultMessage="Abonnement"
                                />
                            </div>
                        </div>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={11}>
                                <div className="info-row">
                                    <label>
                                        <FormattedMessage {...genericMessages.transactionDate} /></label>
                                    <div className="value">{moment(transaction.createdAt).format('DD/MM/YYYY')}</div>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={13}>
                                <div className="info-row">
                                    <label>
                                        <FormattedMessage {...genericMessages.transactionServerQueryTime} /></label>
                                    <div className="value">{moment(transaction.createdAt).format('HH:mm')}</div>
                                </div>
                            </Col>
                        </Row>
                        <div className="info-row">
                            <label>
                                <FormattedMessage
                                    id="transaction.id"
                                    defaultMessage="ID Transaction"
                                />
                            </label>
                            <div className="value">{transaction.id}</div>
                        </div>
                        <div className="info-row">
                            <label> <FormattedMessage {...genericMessages.parking} />
                            </label>
                            <div className="value">{transaction.parkingName}</div>
                        </div>

                        <div className="info-row">
                            <label>
                                <FormattedMessage
                                    id="transaction.offer"
                                    defaultMessage="Offre"
                                />
                            </label>
                            <div className="value">{transaction.offer.offerName}</div>
                        </div>

                        {title && (
                            <div className="info-row">
                                <label>
                                    <FormattedMessage
                                        id="transaction.validity_dates"
                                        defaultMessage="Dates de validité"
                                    />
                                </label>
                                <div className="value">{moment(title.offerPrice?.beginValidityDate).format('DD/MM/YYYY')}  →  {moment(title.offerPrice?.endValidityDate).format('DD/MM/YYYY')}</div>
                            </div>
                        )}

                        <Divider />

                        {transaction.user && (
                            <>
                                <div className="mb-3">
                                    <Typography.Title level={2} className="text-primary">
                                        <FormattedMessage
                                            id="customer"
                                            defaultMessage="Client"
                                        />
                                    </Typography.Title>
                                </div>

                                <div className="info-row">
                                    <label>
                                        <FormattedMessage
                                            id="customer.type"
                                            defaultMessage="Type client"
                                        />
                                    </label>
                                    <div className="value">
                                        {TranslateUserType(transaction.user.userType)}
                                    </div>
                                </div>

                                <UserRow user={transaction.user} />

                                <div className="info-row">
                                    <label>
                                        <FormattedMessage
                                            id="customer.email"
                                            defaultMessage="Adresse e-mail"
                                        />
                                    </label>
                                    <div className="value">
                                        {transaction.user.email}
                                    </div>
                                </div>

                                <div className="info-row">
                                    <label>
                                        <FormattedMessage
                                            id="customer.phone"
                                            defaultMessage="Téléphone"
                                        />
                                    </label>
                                    <div className="value">
                                        {transaction.user.phone}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="info-row">
                            <label>
                                <FormattedMessage
                                    id="customer.invoice_address"
                                    defaultMessage="Adresse de facturation"
                                />
                            </label>
                            <div className="value">
                                <AddressRow address={transaction.billingAddress} />
                            </div>
                        </div>

                        <Row gutter={16}>
                            <Col className="gutter-row" span={14}>
                                <div className="info-row">
                                    <label> <FormattedMessage {...genericMessages.badgeType} /></label>
                                    <div className="value">{TranslateBadgeType(transaction.badgeType)}</div>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={10}>
                                <div className="info-row">
                                    <label>
                                        <FormattedMessage
                                            id="badge.shipping_status"
                                            defaultMessage="Statut de l'envoi"
                                        />
                                    </label>
                                    <div className="value">
                                        <TagTransactionStatus value={(transaction.sendBadges) ? Status.BadgeSent : Status.BadgeNotSent} />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="info-row">
                            <label>
                                <FormattedMessage
                                    id="badge.shipping_address"
                                    defaultMessage="Adresse de livraison"
                                />
                            </label>
                            <div className="value">
                                <AddressRow address={transaction.deliveryAddress} />
                            </div>
                        </div>
                        {transaction.paymentStatus === Status.Paid && (
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                block
                                onClick={deliveryForm}
                            >
                                <FormattedMessage
                                    id="badge.dowload_delivery_receipt"
                                    defaultMessage="Télécharger le bon de livraison"
                                />
                            </Button>
                        )}
                        <Divider />

                        <div className="mb-3">
                            <Typography.Title level={2} className="text-primary">
                                <FormattedMessage
                                    id="badge"
                                    defaultMessage="Badge(s)"
                                />
                            </Typography.Title>
                        </div>

                        <div>
                            {transaction.badges?.map((badge, index) => (
                                <div className="badges" key={'title-' + index}>
                                    <Typography.Title level={2}>
                                        <FormattedMessage
                                            id="badge.number"
                                            defaultMessage="Badge n°"
                                        /> {index + 1}
                                    </Typography.Title>

                                    <UserRow user={badge.handler} />

                                    <div className="info-row">
                                        <label><FormattedMessage {...genericMessages.cardNumber} /></label>
                                        <div className="value">
                                            <Tag>{badge.titleNumber}</Tag>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <Divider />

                        <div className="mb-3">
                            <Typography.Title level={2} className="text-primary">
                                <FormattedMessage {...genericMessages.payment} />
                            </Typography.Title>
                        </div>

                        <div className="info-row">
                            <label> <FormattedMessage {...genericMessages.subscriptionPaymentForm} /></label>
                            {transaction.psp === PSPName.payline && (<div className="value">Payline</div>)}
                            {transaction.psp === PSPName.nmi && (<div className="value">NMI</div>)}
                        </div>

                        {transaction.paymentToken && transaction.psp === PSPName.payline && (
                            <Row gutter={24}>
                                <Col className="gutter-row" span={24}>
                                    <div className="info-row">
                                        <label> <FormattedMessage {...genericMessages.paylineId} /></label>
                                        <div className="value"><small>{transaction.paymentToken}</small></div>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        {transaction.paymentToken && transaction.psp === PSPName.nmi && (
                            <Row gutter={24}>
                                <Col className="gutter-row" span={24}>
                                    <div className="info-row">
                                        <label> <FormattedMessage {...genericMessages.nmmiId}/></label>
                                        <div className="value"><small>{transaction.paymentToken}</small></div>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        {(transaction.paylineTransactionResult && transaction.paylineTransactionResult?.longMessage) && (
                            <div className="info-row">
                                <label> <FormattedMessage {...genericMessages.paylineStatus}/></label>
                                <div className="value"><Tag>{transaction.paylineTransactionResult.longMessage}</Tag></div>
                            </div>
                        )}
                        {(transaction.nmiTransactionResult && transaction.nmiTransactionResult?.message) && (
                            <div className="info-row">
                                <label> <FormattedMessage {...genericMessages.nmiStatus} /></label>
                                <div className="value"><Tag>{transaction.nmiTransactionResult.message}</Tag></div>
                            </div>
                        )}

                        {(transaction.totalDueAmount > 0) && (
                            <div className="info-row">
                                <label> <FormattedMessage {...genericMessages.amount} /></label>
                                <div className="value prices-table">
                                    {transaction.dutyFreeAmount && (
                                        <div className="price-row">
                                            <span className="price-label"><FormattedMessage {...genericMessages.priceTotalExcludingTaxes} /></span>
                                            <span className="price-value">
                                                <Price value={transaction.dutyFreeAmount} currency={transaction.currencyIso} currencyCentsDigits={transaction.currencyCentsDigits} />
                                            </span>
                                        </div>
                                    )}
                                    {transaction.duties.map((tax, index) => (
                                        <div className="price-row" key={'tax-row-' + index}>
                                            <span className="price-label">{tax.name} ({tax.rate}%)</span>
                                            <span className="price-value"><Price value={tax.amount} /></span>
                                        </div>
                                    ))}
                                    <div className="price-row">
                                        <span className="price-label"><strong><FormattedMessage {...genericMessages.priceTotalIncludingTaxes} /></strong></span>
                                        <span className="price-value">
                                            <strong>
                                                <Price value={transaction.totalDueAmount} currency={transaction.currencyIso} currencyCentsDigits={transaction.currencyCentsDigits} />
                                            </strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {transaction.paymentStatus === Status.Paid && (
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                block
                                onClick={invoice}
                            >
                                <FormattedMessage
                                    id="subscription.download_receipt"
                                    defaultMessage="Télécharger le reçu"
                                />
                            </Button>
                        )}
                    </>
                )}
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    transactions: state.transactions,
});

export default connect(
    mapStateToProps,
    {
        getDetails: transactionDetails.trigger,
        detailsReset: transactionDetails.reset,
        getInvoice: transactionsInvoice.trigger,
        getDeliveryForm: transactionsDeliveryForm.trigger,
    },
)(SubscriptionDrawer);
