import React, { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Dropdown, Avatar, Menu } from 'antd';

import { logout as logoutAction, AuthState } from '../store/actions/auth';
import { MainReducerState } from '../store/reducers';
import { getRoute, RoutePathName } from '../routes';
// import { User } from '../store/api/apiTypes';
import SiteSettingsDrawer from '../pages/sites/SiteSettingsDrawer';
import { FormattedMessage } from 'react-intl';
import logo from '../assets/images/logo.png';
import { User, UserRole } from '../store/api/apiTypes';
import { hasRole } from '../helpers/security';
import { ThemeState } from '../store/actions/theme';
import genericMessages from '../locale/genericMessages';
interface HeaderProps {
    themeState: ThemeState;
    authState: AuthState;
    logout: typeof logoutAction.trigger;
}

const Header: FC<HeaderProps> = ({
    themeState,
    authState,
    logout ,
}) => {

    const user: User | undefined = authState.user;
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const location = useLocation();

    const openDrawer = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsDrawerVisible(true);
        return false;
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const menu = (
        <Menu>
            {(user && hasRole(user, UserRole.Site) && user.sites.length === 1) && (
                <Menu.Item>
                    <a href="#settings" onClick={openDrawer}>Paramètres du site</a>
                </Menu.Item>
            )}
            <Menu.Item>
                <Link to={getRoute(RoutePathName.login)} onClick={logout}>Se déconnecter</Link>
            </Menu.Item>
        </Menu>
    );

    let menuSelectedKey = location.pathname;
    if (location.pathname === getRoute(RoutePathName.subscriptions)) {
        menuSelectedKey = getRoute(RoutePathName.transactions);
    }

    return (
        <Layout.Header id="main-header">
            <div id="logo-wrapper">
                <Link to={getRoute(RoutePathName.transactions)}>
                    <img src={(themeState?.logo) ? themeState?.logo : logo} alt="logo" />
                </Link>
            </div>
            {(hasRole(user, UserRole.SuperAdmin) || hasRole(user, UserRole.Admin)) && (
                <Menu
                    mode="horizontal"
                    className="main-menu"
                    defaultSelectedKeys={[location.pathname]}
                    selectedKeys={[menuSelectedKey]}
                >
                    <Menu.Item key={getRoute(RoutePathName.transactions)}>
                        <Link to={getRoute(RoutePathName.transactions)}>
                            <span> <FormattedMessage {...genericMessages.headerTransactions}/> </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={getRoute(RoutePathName.sites)}>
                        <Link to={getRoute(RoutePathName.sites)}>
                            <span><FormattedMessage  {...genericMessages.headerSites}/></span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={getRoute(RoutePathName.users)}>
                        <Link to={getRoute(RoutePathName.users)}>
                            <span><FormattedMessage  {...genericMessages.headerUsers}/></span>
                        </Link>
                    </Menu.Item>
                </Menu>
            )}
            {(hasRole(user, UserRole.Site) && user?.sites.length === 1) && (
                <h1 className="site-title text-primary">{user?.sites[0].name}</h1>
            )}

            {(authState.user && authState.user.firstName) && (
                <Dropdown overlayClassName="user-card-dropdown" trigger={['click']} overlay={menu}>
                    <Link to="" className="user-card">
                        <Avatar size={32}>{authState.user?.firstName.substr(0, 1) + authState.user?.lastName.substr(0, 1)}</Avatar>
                        {authState.user?.firstName} {authState.user?.lastName}
                    </Link>
                </Dropdown>
            )}

            {(user && user.sites.length === 1) && (
                <SiteSettingsDrawer
                    id={user?.sites[0].id}
                    isVisible={isDrawerVisible}
                    onClose={onDrawserClose}
                    onSuccess={onDrawserClose}
                />
            )}
        </Layout.Header>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: state.auth,
    themeState: state.theme,
});

export default connect(
    mapStateToProps,
    {
        logout: logoutAction.trigger,
    },
)(Header);
