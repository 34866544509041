import { Site, UploadType } from './apiTypes';
import { SearchPaginationQuery } from './';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

export interface SiteDetailsPayload {
    id: string;
}

export interface SiteUploadPayload {
    id: string;
    file: RcFile;
    type: UploadType;
}

export type SiteListPayload = SearchPaginationQuery;

export const details = (payload: SiteDetailsPayload) => api.get(`/cms/sites/${payload.id}`);

export const list = (payload: SiteListPayload = {}) => {
    return api.get(`/cms/sites`, {params: cleanPayload(payload)});
};

export const create = (payload: Site) => {
    const formData = serialize(payload);

    try {
        return api.post(`/cms/sites`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } catch (error) {
        console.log('Error creating site:', error);
        throw error;
    }
};

export const update = (payload: Site) => {
    const id: string = payload.id;
    // delete payload.id;

    const formData = serialize(payload);
    try {
        return api.put(`/cms/sites/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } catch (error) {
        console.log('Error updating site:', error);
        throw error;
    }
};

export const remove = (payload: Site) => {
    return api.delete(`/cms/sites/${payload.id}`);
};

export const upload = (payload: SiteUploadPayload) => {

    const formData = new FormData();
    formData.append('image', payload.file);

    return api.post(`/cms/sites/${payload.id}/${payload.type}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const templates = (payload = {}) => api.get(`cms/templates`);
