import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { Transaction, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    TransactionDetailsPayload,
    TransactionListPayload,
    details as detailsApiCall,
    list as listApiCall,
    exportTransactions as exportApiCall,
    invoice as invoiceApiCall,
    deliveryForm as deliveryFormApiCall,
    TransactionValidationPayload,
    validate as validateAoiCall,
} from '../api/transactions';

// State

export interface TransactionsState {
    list: RequestState<ListResponse<Transaction>>;
    details: RequestState<Transaction | undefined>;
    export: RequestState<string | undefined>;
    invoice: RequestState<string | undefined>;
    deliveryForm: RequestState<string | undefined>;
    validate: RequestState<Transaction | undefined>;
}

const initialState: TransactionsState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    export: {
        data: undefined,
        loading: false,
        success: false,
    },
    invoice: {
        data: undefined,
        payload: undefined,
        loading: false,
        success: false,
    },
    deliveryForm: {
        data: undefined,
        payload: undefined,
        loading: false,
        success: false,
    },
    validate: {
        data: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    TransactionsState['list'],
    TransactionListPayload,
    ListResponse<Transaction>
>('transactions/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const details = new EzeeAsyncAction<
    TransactionsState['details'],
    TransactionDetailsPayload,
    Transaction
>('transactions/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const exportTransactions = new EzeeAsyncAction<
    TransactionsState['export'],
    TransactionListPayload,
    any
>('transactions/export', initialState.export, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.export,
    }),
});

export const invoice = new EzeeAsyncAction<
    TransactionsState['invoice'],
    Transaction,
    string
>('transactions/invoice', initialState.invoice, {
    trigger: (state, payload) => ({
        ...state,
        payload,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, data) => ({
        ...state,
        data,
        loading: false,
        success: true,
    }),
    failure: (state, data) => ({
        ...state,
        loading: false,
        error: data,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.invoice,
    }),
});

export const deliveryForm = new EzeeAsyncAction<
    TransactionsState['deliveryForm'],
    Transaction,
    string
>('transactions/deliveryForm', initialState.deliveryForm, {
    trigger: (state, payload) => ({
        ...state,
        payload,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, data) => ({
        ...state,
        data,
        loading: false,
        success: true,
    }),
    failure: (state, data) => ({
        ...state,
        loading: false,
        error: data,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.deliveryForm,
    }),
});

export const validate = new EzeeAsyncAction<
    TransactionsState['validate'],
    TransactionValidationPayload,
    Transaction
>('transaction/validate', initialState.validate, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

// Reducer

export const transactionsReducer = combineReducers<TransactionsState>({
    details: details.reducer,
    list: list.reducer,
    export: exportTransactions.reducer,
    invoice: invoice.reducer,
    deliveryForm: deliveryForm.reducer,
    validate: validate.reducer,
});

// Saga

export function* transactionsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(exportTransactions.type.trigger, simpleAsyncSaga(exportApiCall, exportTransactions));
    yield takeLatest(invoice.type.trigger, simpleAsyncSaga(invoiceApiCall, invoice));
    yield takeLatest(deliveryForm.type.trigger, simpleAsyncSaga(deliveryFormApiCall, deliveryForm));
    yield takeLatest(validate.type.trigger, simpleAsyncSaga(validateAoiCall, validate));
}

// Store helpers

export const getTransactionsState = (state: MainReducerState) => state.transactions;
