import * as React from 'react';
import { SupportedLanguage } from '../store/api/apiTypes';

import '../assets/styles/components/flag.less';

interface FlagProps {
    language: SupportedLanguage;
}

const Flag: React.FC<FlagProps> = ({ language }) => (
    <span className={'flag ' + language} />
);

export default Flag;
