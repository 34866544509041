// Types of the backend API

type ObjectId = string;

export interface Organization {
    id: string;
    name: string;
}

export interface User {
    id: string;
    userType: UserType;
    companyName: string;
    civility: Civility;
    title?: Civility;
    firstName: string;
    lastName: string;
    name?: string;
    phone: string;
    siret: string;
    vatCode: string;
    textArea: string;
    email: string;
    role?: UserRole;
    site: Site;
    sites: Site[];
    organization?: any;
    addresses: Address[];
    defaultAddress: Address;
    transactions?: Transaction[];
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScopes]?: any[];
    };
}

export interface Scope {
    agencies: ObjectId[];
}

export interface Role {
    id: ObjectId;
    name: string;
    removable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScopes]?: boolean;
    };
}

export interface Transaction {
    id: string;
    paymentStatus: Status;
    titleId: string;
    ticketId: string;
    entryDateTime: Date;
    transactionId?: string;
    parkingName: string;
    totalDueAmount: number;
    dutiesAmount: number;
    dutyFreeAmount?: number;
    alreadyPaidCharge?: number;
    exitDateTime: Date;
    currencyIso: string;
    currencyCentsDigits: number;
    createdAt: Date;
    updatedAt: Date;
    zoneName: string;
    paymentToken: string;
    paylineTransactionResult: any;
    nmiTransactionResult: any;
    advamTransactionResult: any;
    duties: Tax[];
    transactionType: ServiceType;
    account?: User;
    badgeType: BadgeType;
    titles?: Title[];
    deadline?: boolean;
    billingAddress?: Address;
    deliveryAddress?: Address;
    user: User;
    badges: BadgeInfo[];
    psp?: string;
    pspStatusCode?: string;
    pspTransactionDate?: string;
    sendBadges: boolean;
    offer: Offer;
    pspTransactionId?: string;
    validationStatus: TransactionValidationStatus;
    validationComment?: string;
}

export enum TransactionValidationStatus {
    pending = 'pending',
    refused = 'refused',
    accepted = 'accepted',
}

export interface BadgeInfo {
    titleId: number;
    titleNumber: string;
    id?: string;
    handler?: UserInfo;
    withBarcode?: boolean;
    canRenew?: boolean;
    beginRenewDate?: Date;
    endRenewDate?: Date;
    offer?: Offer;
    offerId?: string;
    transaction?: Transaction;
    offerType?: string;
    info?: TitleInfo;
}

export interface Title {
    id?: string;
    titleId?: number;
    offerPrice?: OfferPrice;
    offerSale: any;
    handler?: UserInfo;
    licensePlates?: string[];
    withBarcode?: boolean;
    canRenew?: boolean;
    beginRenewDate?: Date;
    endRenewDate?: Date;
    offer?: Offer;
    offerId?: string;
    titleNumber?: string;
    transaction?: Transaction;
    offerType?: string;
    info?: TitleInfo;
}

export interface SellingPrice {
    dutiesAmount: DutiesAmount[];
    dutyFreeAmount: number;
    totalDueAmount: number;
}

export interface DutiesAmount {
    name?: string;
    amount: number;
    rate: number;
}

export interface Offer {
    offerId: string;
    name: string;
    beginValidityDate?: string;
    endValidityDate?: string;
    isPromotional?: boolean;
    offerName?: string;
    offerType?: string;
    periodTypes?: PeriodType[];
    sellingPrice?: SellingPrice;
    months?: number;
    aboPriceByMonth?: number;
    vehicleClass?: VehicleType;
    zones?: Zone[];
}

export interface Zone {
    parkingId: Parking['id'];
    parkingName: string;
    zoneName: string;
}

export interface Parking {
    id: number;
    name: string;
    address?: string;
    zipCode?: string;
    city?: string;
    geoCoordinate?: GeoCoordinate;
    nbSpaces?: number;
    phoneNumber?: string;
}

export interface GeoCoordinate {
    latitude?: string;
    longitude?: string;
    altitude?: string;
}

export interface OfferPrice {
    id: string;
    name: string;
    beginValidityDate?: string;
    endValidityDate?: string;
    periodType: PeriodType;
    price: SellingPrice;
}

export interface TitleInfo {
    handler?: UserInfo;
    licensePlates?: string[];
    offerPrice?: OfferPrice;
}

export interface UserInfo {
    title?: Civility;
    civility: Civility;
    name: string;
    firstName: string;
    lastName: string;
    licensePlates?: string[];
    isCompany?: boolean;
}

export interface Address {
    userType: UserType;
    companyName: string;
    firstName: string;
    lastName: string;
    civility: Civility;
    street: string;
    complement: string;
    zipCode: string;
    city: string;
    country: string;
    default: boolean;
}

export enum VehicleType {
    all = 'All',
    car = 'Car',
    motorcycle = 'Motorcycle',
    small = 'SmallVehicle',
    heavy = 'HeavyVehicle',
}

export enum Civility {
    mister = 'mr',
    missus = 'mrs',
    ms = 'ms',
    miss = 'miss',
    other = 'other',
}

export enum UserType {
    individual = 'individual',
    company = 'company',
}

export enum PeriodType {
    startNextMonth = 'StartNextMonth',
    endOfCurrentMonth = 'EndOfCurrentMonth',
    endOfCurrentMonthThenNextPeriod = 'EndOfCurrentMonthThenNextPeriod',
    fixedDate = 'FixedDate',
}

export enum AddressType {
    delivery = 'delivery',
    billing = 'billing',
}

export enum BadgeType {
    dematerialized = 'Dematerialized',
    physical = 'Physical',
    dematerializedAndPhysical = 'DematerializedAndPhysical',
}

export enum ServiceType {
    payExt = 'PayExt',
    ppo = 'Ppo',
}

export enum AlignmentType {
    right = 'Right',
    left = 'Left',
    center = 'Center',
}

export interface Tax {
    amount: number;
    name: string;
    rate: number;
}

export interface Site {
    _id: string;
    id: string;
    name: string;
    parcName?: string;
    phone?: string;
    apimKey?: string;
    service?: string;
    services?: string[];
    siteCode: number;
    contactEmail: string;
    login: string;
    password: string;
    siteId: string;
    currencyIso?: string;
    currencyCentsDigits?: number;
    deviceId?: number;
    parcId?: number;
    deviceName?: string;
    themeColor: string;
    logoImage?: string;
    bannerImage?: string;
    loginImage?: string;
    receiptImage?: string;
    enabled: boolean;
    paymentToken: string;
    pages: Page[];
    psp: Psp;
    receiptLogoAlignment: AlignmentType;
    adminUsers: User[];
}

export enum PSPName {
    payline = 'payline',
    nmi = 'nmi',
    advam = 'advam',
    globalPayment = 'globalPayment',
}
export interface Psp {
    name: PSPName;
    payline_contractNumber?: string;
    nmi_security_key?: string;
    advam_account_number?: string;
    operator_username?: string;
    operator_password?: string;
}

export interface Page {
    id: string;
    slug: string;
    title: Translation;
    content: Translation;
}

export enum SupportedLanguage {
    fr = 'fr',
    en = 'en',
    es = 'es',
}

export const LanguagesList: SupportedLanguage[] = [
    SupportedLanguage.fr,
    SupportedLanguage.en,
    SupportedLanguage.es,
];

export type Translation = {
    [key in SupportedLanguage]?: string;
};

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface TemplatesResponse {
    receipt?: string;
    subscriptionEmail?: string;
    validationRequestEmail?: string;
}
// ------------------------------------------
// ENUMs

export enum PermissionRight {
    read        = 'r',
    write       = 'w',
    disabled    = 'disabled',
}

export enum Permission {
    SuperAdmin = 'superAdmin',
    Roles = 'roles',
}

export enum UserRole {
    Admin = 'admin',
    SuperAdmin = 'superAdmin',
    Site = 'site',
    Customer = 'customer',
}

export enum RoleScopes {
    User = 'user',
}

export enum Status {
    Unstarted = 'unstarted',
    Pending = 'pending',
    Processing = 'processing',
    Cancelled = 'cancelled',
    Paid = 'paid',
    Abandonned = 'abandonned',
    Rejected = 'rejected',
    InternalError = 'internalError',
    NothingToPay = 'nothingToPay',

    BadgeSent = 'badgeSent',
    BadgeNotSent = 'badgeNotSent',
}

export enum UploadType {
    logo = 'LogoUpload',
    banner = 'BannerUpload',
    login = 'LoginImageUpload',
    receipt = 'ReceiptImageUpload',
}
