import api from './_client';
import { User } from './apiTypes';

export interface LoginPayload {
    username: string;
    password: string;
}

export const login = (payload: LoginPayload) => api.post('/cms/adminLogin', payload);

export const logout = () => api.post('/cms/logout');

export const checkLoginStatus = () => api.get('/cms/me');

export interface ForgottenPasswordPayload {
    username: User['email'];
}

export const forgottenPassword = async (payload: ForgottenPasswordPayload) => api.post('/auth/reset', payload);

export interface ResetPasswordPayload {
    token: string;
    password: string;
}

export const resetPassword = async (payload: ResetPasswordPayload) => api.post('/signedAction', payload);

export interface ResendInvitationPayload {
    token: string;
}

export const resendInvitation = async (payload: ResendInvitationPayload) => api.post('/auth/reset', payload);
