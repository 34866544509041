import React, { FC, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { Typography, Switch, Button, Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { MainReducerState } from '../../store/reducers';
import { getCookiesState, cookies, CookiesState, CookieNames } from '../../store/actions/cookies';

import { acceptAllCookies, refuseAllCookies, removeGoogleAnalytics, addGoogleAnalytics, setCookiesOptions } from '../../helpers/cookies';
import Seo from '../../components/Seo';
import { FormattedMessage, useIntl } from 'react-intl';

// tslint:disable: max-line-length

interface PrivacyProps {
    accept: typeof cookies.actions.acceptAll;
    cookiesState: CookiesState;
    refuse: typeof cookies.actions.refuse;
    toggle: typeof cookies.actions.toggle;
}

const Privacy: FC<PrivacyProps> = ({
    accept, cookiesState, refuse, toggle,
}) => {
    const [_, setCookie, removeCookie] = useCookies(); // eslint-disable-line @typescript-eslint/no-unused-vars
    const { formatMessage } = useIntl();
    const [showSuccess, setShowSuccess] = useState(false);
    const location = useLocation();
    const onToggle = (cookie: string) => {
        toggle(cookie);

        if (cookie === CookieNames.GA) {
            if (cookiesState.ga) {
                removeGoogleAnalytics(removeCookie);
                setCookie(CookieNames.GA, 0, setCookiesOptions);
            } else {
                addGoogleAnalytics(location.pathname);
                setCookie(CookieNames.GA, 1, setCookiesOptions);
            }
        }
    };
    const onClickAccept = useCallback(() => {
        acceptAllCookies(setCookie, location.pathname);
        accept();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [accept, location.pathname, setCookie]);
    const onClickRefuse = useCallback(() => {
        refuseAllCookies(setCookie, removeCookie);
        refuse();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [refuse, removeCookie, setCookie]);

    return (
        <div className="container page">
            <Seo title={formatMessage({ id: 'privacy.title', defaultMessage: 'Politique de confidentialité et Protection de la vie privée' })} />
            <Typography.Title level={1}>
                <FormattedMessage
                    id="privacy.privacy_policy"
                    defaultMessage="Politique de confidentialité et Protection de la vie privée"
                />
            </Typography.Title>
            <Typography.Title level={2} id="cookies">
                <FormattedMessage
                    id="privacy.cookie_choice"
                    defaultMessage="Choix des cookies"
                />
            </Typography.Title>

            <Typography.Title level={3}>
                <FormattedMessage
                    id="privacy.optionals"
                    defaultMessage="Optionnels"
                />
            </Typography.Title>
            <ul>
                <li className="flex">
                    <Switch checked={!!cookiesState.ga} onChange={onToggle.bind(null, 'ga')} />
                    <p>
                        <FormattedMessage
                            id="private.google_analytics"
                            defaultMessage="Cookies de Google Analytics destinés à réaliser des statistiques de navigation et de fréquentation"
                        />
                        <code>ezee-ga, _gat, _gid, _ga</code>
                    </p>
                </li>
            </ul>
            <Typography.Title level={3}>
                <FormattedMessage
                    id="privacy.mandatory"
                    defaultMessage="Obligatoires"
                />
            </Typography.Title>
            <ul>
                <li className="flex">
                    <Switch checked disabled />
                    <p>
                        <FormattedMessage
                            id="privacy.cookie_refusal"
                            defaultMessage="Cookie permettant de déterminer si l\'utilisateur a accepté ou refusé un ou plusieurs cookies"
                        />
                        <code>ezee-has_made_choice</code>
                    </p>
                </li>
            </ul>
            <div className="flex">
                <Button
                    type="ghost"
                    onClick={onClickRefuse}
                >

                    <FormattedMessage
                        id="privacy.deny_all"
                        defaultMessage="Tout refuser"
                    />
                </Button>
                <Button
                    type="primary"
                    onClick={onClickAccept}
                >

                    <FormattedMessage
                        id="privacy.accept_all"
                        defaultMessage="Tout accepter"
                    />
                </Button>
                {showSuccess && (
                    <div>
                        <Tag
                            icon={<CheckCircleOutlined />}
                            color="success"
                        >

                            <FormattedMessage
                                id="privacy.saved"
                                defaultMessage="Enregistré"
                            />
                        </Tag>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    cookiesState: getCookiesState(state),
});

export default connect(
    mapStateToProps,
    {
        accept: cookies.actions.acceptAll,
        refuse: cookies.actions.refuse,
        toggle: cookies.actions.toggle,
    },
)(Privacy);
