import React, { FC } from 'react';
import { ThemeState, getThemeState } from '../store/actions/theme';
import { connect } from 'react-redux';
import { MainReducerState } from '../store/reducers';

const hexToRgba = (hex: string, opacity: number = 1) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + opacity + ')' : null;
};

interface ThemeProps {
    themeState: ThemeState;
}

const Theme: FC<ThemeProps> = ({themeState}) => (
    <style
        dangerouslySetInnerHTML={{__html: `
            ::selection {
                background: ` + themeState.color + `;
            }

            a,
            .ant-radio-button-wrapper:hover {
                color: ` + themeState.color + `;
            }

            .text-primary{
                color: ` + themeState.color + ` !important;
            }

            .ant-radio-inner::after,
            .ant-btn-primary,
            .ant-btn-primary:hover,
            .ant-btn-primary:focus,
            #main-header .user-card .ant-avatar,
            body .ant-badge-count,
            .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
            .ant-picker-range .ant-picker-active-bar
            {
                background-color: ` + themeState.color + `;
            }

            .text-primary,
            .ant-table-filter-trigger.active,
            .ant-btn-link,
            .ant-dropdown-menu-item-selected,
            .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active,
            .ant-btn:hover, .ant-btn:focus,
            .ant-spin,
            .main-menu.ant-menu-horizontal .ant-menu-item-selected a,
            .main-menu.ant-menu-horizontal a:hover
            {
                color: ` + themeState.color + `;
                border-color: ` + themeState.color + `;
            }

            .ant-radio-checked .ant-radio-inner,
            .ant-btn-primary,
            .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
            .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before
            {
                border-color: ` + themeState.color + `;
            }

            .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
            .ant-checkbox-checked .ant-checkbox-inner
            {
                border-color: ` + themeState.color + `;
                background-color: ` + themeState.color + `;
            }

            .ant-input-affix-wrapper:focus,
            .ant-input-affix-wrapper-focused,
            .ant-select:focus,
            .ant-select-focused,
            .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
            .ant-picker-focused,
            .ant-picker:focus,
            .ant-input:focus,
            .ant-input-focused
            {
                box-shadow: 0 0 0 2px ` + hexToRgba(themeState.color, 0.2) + `;
                border-color: ` + themeState.color + `;
            }

            .ant-tabs-ink-bar,
            .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
            .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before
            {
                background-color: ` + hexToRgba(themeState.color, 0.3) + `;
            }

            .ant-btn-primary:hover,
            .ant-btn-primary:focus
            {
                color: #FFF;
            }

            .ant-btn-primary:hover
            {
                opacity: 0.8;
            }
        `}}
    />
);

const mapStateToProps = (state: MainReducerState) => ({
    themeState: getThemeState(state),
});

export default connect(
    mapStateToProps,
    {
    },
)(Theme);
