// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/flags/flag-fr.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../images/flags/flag-en.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../images/flags/flag-es.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../images/flags/flag-de.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../images/flags/flag-pt.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../images/flags/flag-it.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, ".flag {\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  background-size: contain;\n  background-position: center center;\n  background-repeat: no-repeat;\n}\n.flag.fr {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.flag.en {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.flag.es {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.flag.de {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.flag.pt {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n.flag.it {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\n.tab-lang {\n  margin-bottom: 2em !important;\n  overflow: unset !important;\n}\n.tab-lang .ant-tabs-bar {\n  margin-bottom: 0 !important;\n}\n.tab-lang .ant-tabs-tab {\n  margin-right: 1.5em;\n}\n", ""]);
// Exports
module.exports = exports;
