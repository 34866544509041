import { Col, Row } from 'antd';
import * as React from 'react';
import { TranslateCivility } from '../../helpers/translate';
import { User, UserInfo } from '../../store/api/apiTypes';
import formMessage from '../../locale/formMessages';
import genericMessages from '../../locale/genericMessages';
import { FormattedMessage } from 'react-intl';
interface UserRowProps {
    user ?: User | UserInfo;
}

const UserRow: React.FC<UserRowProps> = ({ user }) => {
    return user ? (
        <Row gutter={16}>
            <Col className="gutter-row" span={4}>
                <div className="info-row">
                    <label> <FormattedMessage {...formMessage.civility_label} />  </label>
                    <div className="value">
                        {user.title ? TranslateCivility(user.title) : TranslateCivility(user.civility)}
                    </div>
                </div>
            </Col>
            <Col className="gutter-row" span={10}>
                <div className="info-row">
                    <label><FormattedMessage {...genericMessages.userLastName} /> </label>
                    <div className="value">
                        {user?.name ? user.name : user.lastName}
                    </div>
                </div>
            </Col>
            <Col className="gutter-row" span={10}>
                <div className="info-row">
                    <label><FormattedMessage {...genericMessages.userFirstName} /></label>
                    <div className="value">{user.firstName}</div>
                </div>
            </Col>
        </Row>
    ) : null;
};

export default UserRow;
