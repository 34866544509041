import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Select, Spin, notification } from 'antd';
import { MainReducerState } from '../../store/reducers';
import { connect } from 'react-redux';
import { User, Role, UserRole } from '../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';
import { FormattedMessage, useIntl } from 'react-intl';
import genericMessages from '../../locale/genericMessages';

import {
    UsersState,
    details as userDetails,
    update as userUpdate,
    create as userCreate,
    resetPassword as userResetPassword,
} from '../../store/actions/users';
import SiteFilterSelect from '../sites/SiteFilterSelect';
import { AuthState } from '../../store/actions/auth';

interface UserDrawerProps extends RouteProps {
    id?: User['id'];
    isVisible: boolean;
    roles: Role[];
    authState: AuthState;
    onClose: () => void;
    onSuccess?: () => void;

    users: UsersState;
    getDetails: typeof userDetails.trigger;
    detailsReset: typeof userDetails.reset;
    update: typeof userUpdate.trigger;
    updateReset: typeof userUpdate.reset;
    create: typeof userCreate.trigger;
    createReset: typeof userCreate.reset;

    resetPassword: typeof userResetPassword.trigger;
    resetPasswordReset: typeof userResetPassword.reset;
}

const UserDrawer: FC<UserDrawerProps> = ({
    id,
    isVisible,
    authState,
    onClose,
    onSuccess,

    users,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,

    resetPassword,
    resetPasswordReset,
}) => {

    const user: User | undefined = users.details.data;
    const loggedUser: User | undefined = authState.user;
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();

    const [currentRole, setCurrentRole] = useState<UserRole | undefined>();

    useEffect(() => {
        if (isVisible && id) {
            getDetails({ id });
        } else {
            detailsReset();
            setCurrentRole(undefined);
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (users.details.data) {
            form.setFieldsValue(users.details.data);
            setCurrentRole(form.getFieldValue('role'));
        }
    }, [users.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (users.update.success || users.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            if (onSuccess) {
                onSuccess();
            }
        }

        if (users.update.error || users.create.error) {
            updateReset();
            createReset();

            let errorMessage = formatMessage({ id: 'error.user_save', defaultMessage: 'Une erreur est survenue lors de la sauvegarde de cet utilisateur' });

            if ([users.create.error?.data?.error_code, users.create.error?.data?.error_code].includes('email_already_used')) {
                errorMessage = formatMessage({ id: 'error.user_email_already_used', defaultMessage: 'Cet email est déjà utilisé par un autre compte' });
            }
            if ([users.create.error?.data?.error_code, users.create.error?.data?.error_code].includes('bad_email')) {
                errorMessage = formatMessage({ id: 'error.user_email_invalid', defaultMessage: 'Veuillez renseigner un email correct' });
            }
            notification.error({
                message: errorMessage,
                placement: 'bottomRight',
            });
        }
    }, [users.update.success, users.create.success, users.update.error, users.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            if (id) {
                values.id = id;
                update(values);
            } else {
                create(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onRoleChange: SelectProps<Role['id']>['onChange'] = (role, option) => {
        setCurrentRole(role as UserRole);
    };

    // ---------------------------------------
    // Reset password

    const onResetPassword = () => {
        resetPassword();
    };

    useEffect(() => {
        resetPasswordReset();
        if (users.update.success) {
            notification.success({
                message: formatMessage(genericMessages.resetPassword),
                description: formatMessage(genericMessages.resetPasswordOk),
            });
        }

        if (users.update.error) {
            notification.error({
                message: formatMessage(genericMessages.resetPassword),
                description: formatMessage(genericMessages.resetPasswordFailed),
            });
        }
    }, [users.resetPassword.success, users.resetPassword.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const getAvalableRoles = (role ?: UserRole) => {
        switch (role) {
            case UserRole.SuperAdmin:
                return (
                    <>
                    <Select.Option value={UserRole.SuperAdmin}>{formatMessage(genericMessages.userRoleSuperAdmin)}</Select.Option>
                    <Select.Option value={UserRole.Admin}>{formatMessage(genericMessages.userRoleAdmin)}</Select.Option>
                    <Select.Option value={UserRole.Site}>{formatMessage(genericMessages.userRoleSite)}</Select.Option>
                    </>
                );
            case UserRole.Admin:
                return (
                    <>
                    <Select.Option value={UserRole.Admin}>{formatMessage(genericMessages.userRoleAdmin)}</Select.Option>
                    <Select.Option value={UserRole.Site}>{formatMessage(genericMessages.userRoleSite)}</Select.Option>
                    </>
                );
            case UserRole.Site:
                return(
                    <>
                    <Select.Option value={UserRole.Site}>{formatMessage(genericMessages.userRoleSite)}</Select.Option>
                    </>
                );
            default:
                    return(
                        <></>
                    );
        }
    };

    return (
        <Drawer
            className="user-drawer"
            title={(!id) ? formatMessage(genericMessages.userCreate) : formatMessage({ id: 'user.edit', defaultMessage: 'Editer un utilisateur' })}
            width={500}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={users.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Form.Item
                        label={formatMessage(genericMessages.userRole)}
                        name="role"
                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                    >
                        <Select
                            disabled={user?.role === 'customer' ? true : false}
                            filterOption={false}
                            onChange={onRoleChange}
                            placeholder={formatMessage(genericMessages.userSelectRole)}
                            size="large"
                            showSearch
                        >
                            {user?.role !== UserRole.Customer
                                ?
                                getAvalableRoles(loggedUser?.role)
                                : (
                                    <>
                                        <Select.Option value={UserRole.Customer}>{formatMessage(genericMessages.userRoleCustomer)}</Select.Option>
                                    </>
                                )}
                        </Select>
                    </Form.Item>

                    {(currentRole) && (
                        <>
                            {(currentRole !== UserRole.Customer) && (
                                <Form.Item
                                    label={formatMessage({ id: 'user.site', defaultMessage: 'Site de rattachement' })}
                                    name="sites"
                                >
                                    <SiteFilterSelect
                                        multi={true}
                                        size="large"
                                        initialValue={users.details.data?.sites}
                                    />
                                </Form.Item>
                            )}

                            <Form.Item
                                label={formatMessage(genericMessages.userLastName)}
                                name="lastName"
                                rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                            >
                                <Input size="large" />
                            </Form.Item>

                            <Form.Item
                                label={formatMessage(genericMessages.userFirstName)}
                                name="firstName"
                                rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                            >
                                <Input size="large" />
                            </Form.Item>

                            <Form.Item
                                label={formatMessage(genericMessages.userEmail)}
                                name="email"
                                rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                            >
                                <Input size="large" autoComplete="none" />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                            loading={users.create.loading || users.update.loading}
                        >
                            {user ? formatMessage(genericMessages.saveChanges) : formatMessage({id: 'user.create', defaultMessage: 'Créer l\'utilisateur'})}
                        </Button>
                    </Form.Item>

                    {user && (
                        <Form.Item className="cta-submit">
                            <Button
                                type="ghost"
                                size="large"
                                shape="round"
                                onClick={onResetPassword}
                                block
                            >
                                <FormattedMessage {...genericMessages.resetPassword} />
                            </Button>
                        </Form.Item>
                    )}
                </Form>
            </Spin>
        </Drawer >
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    users: state.users,
    authState: state.auth,
});

export default connect(
    mapStateToProps,
    {
        getDetails: userDetails.trigger,
        detailsReset: userDetails.reset,
        create: userCreate.trigger,
        createReset: userCreate.reset,
        update: userUpdate.trigger,
        updateReset: userUpdate.reset,
        resetPassword: userResetPassword.trigger,
        resetPasswordReset: userResetPassword.reset,
    },
)(UserDrawer);
