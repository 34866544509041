import { EzeeAction } from '../helpers/EzeeAction';
import { MainReducerState } from '../reducers';

const storageStateName = 'theme-state';

// State

export interface ThemeState {
    color: string;
    logo?: string;
    name: string;
}

// set initial value

const initialStorageState = localStorage.getItem(storageStateName);
const initialDefaultState = {
    color: '#FFCA00',
    logo: undefined,
    name: 'Orbility',
};

const initialState: ThemeState = initialStorageState ? JSON.parse(initialStorageState) : initialDefaultState;

// Actions/Reducers

export const theme = new EzeeAction<ThemeState>('theme', initialState, {
    set: (state, payload) => {
        const newState = {
            ...state,
            [payload.name]: payload.value,
        };

        localStorage.setItem(storageStateName, JSON.stringify(newState));

        return newState;
    },

    reset: (state, payload) => {
        const newState = initialDefaultState;
        localStorage.setItem(storageStateName, JSON.stringify(newState));
        return newState;
    },
});

// Reducer

export const themeReducer = theme.reducer;

// Store helpers

export const getThemeState = (state: MainReducerState) => state.theme;
