import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeSimpleAction } from '../helpers/EzeeSimpleAction';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { User } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    LoginPayload,
    login as loginApiCall,
    logout as logoutApiCall,
    checkLoginStatus as checkLoginStatusApiCall,
    resendInvitation as resendInvitationApiCall,
    resetPassword as resetPasswordApiCall,
    ResendInvitationPayload,
    ResetPasswordPayload,
} from '../api/auth';

// State

export interface AuthState {
    user?: User;
    forgottenPasswordError?: RequestState['error'];
    forgottenPasswordSuccess: boolean;
    resetPasswordError?: RequestState['error'];
    resetPasswordSuccess: boolean;
    resendInvitationError?: RequestState['error'];
    resendInvitationSuccess: boolean;
    hasCheckedLoginStatus: boolean;
    isConnected: boolean;
    loading: boolean;
    error?: any;
}

const initialState: AuthState = {
    hasCheckedLoginStatus: false,
    isConnected: false,
    loading: false,
    forgottenPasswordSuccess: false,
    resetPasswordSuccess: false,
    resendInvitationSuccess: false,
};

// Actions/Reducers

export const login = new EzeeAsyncAction<AuthState, LoginPayload, User>('auth/login', initialState, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        user: payload,
        hasCheckedLoginStatus: true,
        isConnected: true,
        loading: false,
        forgottenPasswordSuccess: false,
        resetPasswordSuccess: false,
        resendInvitationSuccess: false,
    }),
    failure: (state, payload) => ({
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
        forgottenPasswordSuccess: false,
        resetPasswordSuccess: false,
        resendInvitationSuccess: false,
        error: payload,
    }),
    reset: (state) => initialState,
});

export const checkLoginStatus = new EzeeAsyncAction<AuthState, any, User>('auth/checkLoginStatus', initialState, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        user: payload,
        hasCheckedLoginStatus: true,
        isConnected: true,
        loading: false,
        forgottenPasswordSuccess: false,
        resetPasswordSuccess: false,
        resendInvitationSuccess: false,
    }),
    failure: (state, payload) => ({
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
        forgottenPasswordSuccess: false,
        resetPasswordSuccess: false,
        resendInvitationSuccess: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

export const logout = new EzeeAsyncAction<AuthState, any, any, any>('auth/logout', initialState, {
    trigger: (state, payload) => ({
        ...state,
        isConnected: false,
        loading: true,
    }),
    success: (state, payload) => ({
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
        forgottenPasswordSuccess: false,
        resetPasswordSuccess: false,
        resendInvitationSuccess: false,
    }),
    failure: (state, payload) => ({
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
        forgottenPasswordSuccess: false,
        resetPasswordSuccess: false,
        resendInvitationSuccess: false,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

export const resetPassword = new EzeeAsyncAction<AuthState, ResetPasswordPayload, User>(
    'auth/resetPassword',
    initialState,
    {
        trigger: (state) => ({
            ...state,
            loading: true,
            resetPasswordSuccess: false,
            resetPasswordError: undefined,
        }),
        success: (state) => ({
            ...state,
            resetPasswordSuccess: true,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            resetPasswordSuccess: false,
            resetPasswordError: payload,
            loading: false,
        }),
        reset: () => ({
            ...initialState,
        }),
    },
);

export const resendInvitation = new EzeeAsyncAction<AuthState, ResendInvitationPayload, User>(
    'auth/resendInvitation',
    initialState,
    {
        trigger: (state) => ({
            ...state,
            loading: true,
            resendInvitationSuccess: false,
            resendInvitationError: undefined,
        }),
        success: (state) => ({
            ...state,
            resendInvitationSuccess: true,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            resendInvitationSuccess: false,
            resendInvitationError: payload,
            loading: false,
        }),
        reset: () => ({
            ...initialState,
        }),
    },
);

// Reducer

export const authReducer = EzeeSimpleAction.mergeActionReducers<AuthState>([
    login,
    checkLoginStatus,
    logout,
    resendInvitation,
    resetPassword,
]);

// Saga

export function* authSaga() {
    yield takeLatest(login.type.trigger, simpleAsyncSaga(loginApiCall, login));
    yield takeLatest(checkLoginStatus.type.trigger, simpleAsyncSaga(checkLoginStatusApiCall, checkLoginStatus));
    yield takeLatest(logout.type.trigger, simpleAsyncSaga(logoutApiCall, logout));
    yield takeLatest(resendInvitation.type.trigger, simpleAsyncSaga(resendInvitationApiCall, resendInvitation));
    yield takeLatest(resetPassword.type.trigger, simpleAsyncSaga(resetPasswordApiCall, resetPassword));
}

// Store helpers

export const getAuthState = (state: MainReducerState) => state.auth;
export const getUser = (state: MainReducerState) => state.auth.user;
