import React, { FC } from 'react';
import { Layout } from 'antd';
import Header from './Header';
import { LayoutContextProvider } from '../context/LayoutContext';

import '../assets/styles/Login.less';

import loginImage from '../assets/images/login-background.jpg';

const LoginLayout: FC = ({ children }) => {
    const backgroundImage = loginImage;
    return (
        <LayoutContextProvider>
            <Layout id="login-layout">
                <Layout>
                    <Header />
                    <div
                        id="login-section"
                        style={{
                            backgroundImage: 'url(' + backgroundImage + ')',
                        }}
                    >
                        <div className="wrapper">
                            <div id="login-section-inner">{children}</div>
                        </div>
                    </div>
                </Layout>
            </Layout>
        </LayoutContextProvider>
    );
};

export default LoginLayout;
