import * as React from 'react';
import { Tag } from 'antd';
import { TransactionValidationStatus } from '../store/api/apiTypes';
import { TranslateTransactionValidationStatus } from '../helpers';

interface TagTransactionValidationStatusProps {
    value: TransactionValidationStatus;
}

const TagTransactionValidationStatus: React.FC<TagTransactionValidationStatusProps> = ({ value }) => {
    const tags: any = {};
    tags[TransactionValidationStatus.pending] = {color: '#FA8C17'};
    tags[TransactionValidationStatus.accepted] = {color: '#1ED143'};
    tags[TransactionValidationStatus.refused] = {color: '#FF445A'};

    const color: string = (tags[value]) ? tags[value].color : '#808080';

    return (
        <Tag color={color}>{TranslateTransactionValidationStatus(value)}</Tag>
    );
};

export default TagTransactionValidationStatus;
