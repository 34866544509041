import { Button, Drawer, Form, Input, Tabs } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { LanguagesList, Page, SupportedLanguage, Translation } from '../store/api/apiTypes';
import Flag from './Flag';
import ReactQuill from 'react-quill';
import { FormProps } from 'antd/lib/form';

import { FormattedMessage, useIntl } from 'react-intl';
import genericMessages from '../locale/genericMessages';
import { addTextToEditor, removeTextFromEditor } from '../helpers';

const { TabPane } = Tabs;

const toolbarFormat = {
    toolbar: [
        [
            { header: [1, 2, 3] },
        ],
        [
            { font: [] },
            { size: [] },
            { color: [] },
        ],
        ['bold', 'italic', 'underline'],
        ['image', 'link'],
    ],
};

interface Content {
    title: Translation;
    content: Translation;
}

interface PageDrawerProps {
    value?: any;
    initialValue?: any;
    onChange?: (value: Page) => void;
    label: string;
    buttonElement?: (onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => React.ReactNode;
    placeholderSelect?: (onChange: (value: any, option: any) => void, onDeselect: (value: any, option: any) => void) => React.ReactNode;
    visualizeElement?: (onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => React.ReactNode;
    buttonType: 'icon' | 'button';
}

const PageDrawer: React.FC<PageDrawerProps> = ({ onChange, value, initialValue, label, buttonElement, visualizeElement, placeholderSelect, buttonType }) => {
    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ content, setContent ] = useState<Content>(() => {
        if (initialValue?.content && initialValue?.title) {
            return {
                title: {
                    fr: initialValue.title.fr as string,
                    en: initialValue.title.en as string,
                    es: initialValue.title.es as string,
                },
                content: {
                    fr: initialValue.content.fr as string,
                    en: initialValue.content.en as string,
                    es: initialValue.content.es as string,
                },
            };
        }
        return {
            title: {},
            content: {},
        };
    });
    const [showVisualizeDrawer, setShowVisualizeDrawer] = useState(false);
    const [ tabLang, setTabLang ] = useState<SupportedLanguage>(SupportedLanguage.fr);
    const [form] = Form.useForm();
    const {formatMessage} = useIntl();
    const requiredRule = { required: true, message: formatMessage(genericMessages.mandatoryField) };
    const quillRef = useRef<ReactQuill>(null);

    useEffect(() => {
        form.setFieldsValue(initialValue);
    }, [initialValue, form]);

    const openDrawer = () => {
        setShowDrawer(true);
        setTabLang(SupportedLanguage.fr);
    };

    const openVisualizeDrawer = () => {
        setShowVisualizeDrawer(true);
    };

    const onDrawerClose = () => {
        setShowDrawer(false);
        setShowVisualizeDrawer(false);
    };

    const   tabChange = (val: string) => {
        setTabLang((val as SupportedLanguage));
    };

    const onFormFinish: FormProps['onFinish'] = (values: any) => {
        form.validateFields().then(() => {
            if (onChange) {
                onChange(values);
                setContent({
                    ...content,
                    content: values.content,
                    title: values.title,
                });
                setShowDrawer(false);
            }
        });
    };

    return (
        <>
            { buttonElement && buttonType === 'icon' && (
                <div className="flex-between">
                    <span>{label}</span>
                    <div>
                        {buttonElement(openDrawer)}
                        {visualizeElement && buttonType === 'icon' && (
                            <>
                                {visualizeElement(openVisualizeDrawer)}
                            </>
                        )}
                    </div>
                </div>
            )}
            { buttonElement && buttonType === 'button' && (
                    buttonElement(openDrawer)
            )}
            <Drawer
                className="page-drawer"
                title={formatMessage({id: 'page.edit', defaultMessage: 'Editer la page'})}
                width={750}
                onClose={onDrawerClose}
                visible={showDrawer}
            >
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Tabs className="tab-lang" animated={false} onChange={tabChange}>
                        { LanguagesList.map((language: SupportedLanguage) => (
                            <TabPane tab={<Flag language={language} />} key={'content-' + language}>
                                <Form.Item
                                    label={formatMessage({id: 'page.title', defaultMessage: 'Titre de la page'})}
                                    name={['title', language]}
                                    className="item-layout-vertical"
                                    initialValue={initialValue && initialValue.title ? initialValue.title : ''}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                >
                                    <Input size="large" placeholder="Saisir un titre" />
                                </Form.Item>

                                <Form.Item
                                    label={formatMessage({id: 'page.content', defaultMessage: 'Contenu de la page'})}
                                    name={['content', language]}
                                    className="item-layout-vertical"
                                    initialValue={initialValue && initialValue.content ? initialValue.content : ''}
                                    rules={(language === tabLang) ? [requiredRule] : []}
                                >
                                    <ReactQuill
                                        ref={quillRef}
                                        placeholder=""
                                        modules={toolbarFormat}
                                        theme="snow"
                                    />
                                </Form.Item>
                                { placeholderSelect &&
                                    (
                                        <Form.Item
                                            label="Add Placeholders"
                                            name="Placeholders"
                                        >
                                            {
                                                placeholderSelect(
                                                    (valueToAdd: string) => {
                                                        if (typeof valueToAdd === 'string') {
                                                            return addTextToEditor(quillRef, valueToAdd);
                                                        }
                                                    },
                                                    (valueToRemove: string) => {
                                                        if (typeof valueToRemove === 'string') {
                                                            return removeTextFromEditor(quillRef, valueToRemove);
                                                        }
                                                    },
                                                )
                                            }
                                        </Form.Item>
                                    )
                                }
                            </TabPane>
                        ))}
                    </Tabs>

                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                        >
                            <FormattedMessage id="page.update" defaultMessage="Mettre à jour"/>
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Drawer
                className="page-drawer"
                title={formatMessage({id: 'page.visualize', defaultMessage: 'Consulter la page'})}
                width={750}
                onClose={onDrawerClose}
                visible={showVisualizeDrawer}
            >
                <Tabs className="tab-lang" animated={false} onChange={tabChange}>
                    { LanguagesList.map((language: SupportedLanguage) => (
                        <TabPane tab={<Flag language={language} />} key={'content-' + language}>
                            <h1>
                                {content.title[language] ? content.title[language] : (initialValue?.title[language] ? initialValue.title[language] : ``)}
                            </h1>
                            <ReactQuill
                                value={content.content[language] ? content.content[language] : (initialValue?.content[language] ? initialValue.content[language] : ``)}
                                readOnly={true}
                                theme="bubble"
                            />
                        </TabPane>
                    ))}
                </Tabs>
            </Drawer>
        </>
    );
};

export default PageDrawer;
