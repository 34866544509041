import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';
import genericMessages from '../../locale/genericMessages';
import { useIntl, FormattedMessage } from 'react-intl';

import {
    Table,
    Button,
    Badge,
    DatePicker,
    notification,
    Menu,
} from 'antd';

import { Transaction, Site, Status, ServiceType } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import Search from 'antd/lib/input/Search';
import TransactionDrawer from './TransactionDrawer';
import {
    TransactionsState,
    list as transactionsList,
    exportTransactions as transactionsExport,
    invoice as transactionsInvoice,
} from '../../store/actions/transactions';

import { FilterQuery } from '../../store/api';
import { DownloadOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import Seo from '../../components/Seo';
import { downloadFile, TranslateTransactionStatus } from '../../helpers';
import moment from 'moment';
import SiteFilterSelect from '../sites/SiteFilterSelect';
import TagTransactionStatus from '../../components/TagTransactionStatus';
import Price from '../../components/Price';
import { getRoute, RoutePathName } from '../../routes';
import { Link, useLocation } from 'react-router-dom';

const rowKey = (item: Transaction) => `${item.id}`;

interface TransactionsListProps {
    transactions: TransactionsState;
    getList: typeof transactionsList.trigger;
    exportTransactions: typeof transactionsExport.trigger;
    exportTransactionsReset: typeof transactionsExport.reset;
    getInvoice: typeof transactionsInvoice.trigger;
    getInvoiceReset: typeof transactionsInvoice.reset;
}

const TransactionsList: FC<TransactionsListProps> = ({
    transactions,
    getList,
    exportTransactions,
    exportTransactionsReset,
    getInvoice,
    getInvoiceReset,
}) => {

    const location = useLocation();
    const {formatMessage} = useIntl();
    const itemsPerPage: number = 20;
    const [ selectedId, setSelectedId ] = useState<string | undefined>();
    // const [ search, setSearch ] = useState<string>();
    const [ isDrawerVisible, setIsDrawerVisible ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useState<any>({});
    const { RangePicker } = DatePicker;

    // ---------------------------------------
    // Query params

    const urlParams = new URLSearchParams(window.location.search);
    const siteQueryParam = urlParams.get('site');

    // ---------------------------------------
    // Drawer management

    useEffect(() => {
        setLastSearchParams({
            ...lastSearchParams,
            sort: 'createdAt',
            sortOrder: 'desc',
            page: 0,
            pageSize: itemsPerPage,
            transactionType: ServiceType.payExt,
            search: siteQueryParam || undefined,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    useEffect(() => {
        if (lastSearchParams) { getList({...lastSearchParams}); }
    }, [lastSearchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const setSearchParams = (params: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            ...params,
        });
    };

    const onTableChange: TableProps<Transaction>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const filters: FilterQuery['filters'] = [];

        if (tableFilters.status && tableFilters.status.length > 0) {
            filters.push({
                name: 'status',
                value: tableFilters.status,
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            sortOrder: (sorter.order) ? sorter.order : undefined,
            filters,
        };

        setLastSearchParams(newSearchParams);
    };

    const onDateChange = (dates: any) => {
        if (dates) {
            setSearchParams({
                startDate: moment(dates[0]).toDate(),
                endDate: moment(dates[1]).toDate(),
            });
        } else {
            setSearchParams({
                startDate: undefined,
                endDate: undefined,
            });
        }
    };

    // ---------------------------------------
    // Drawer management

    const edit = (id: string) => {
        setSelectedId(id);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList({...lastSearchParams});
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Export current search to CSV

    const onExport = () => {
        exportTransactions(lastSearchParams);
    };

    useEffect(() => {
        if (transactions.export.success && transactions.export.data) {
            downloadFile(`export_${moment().format('YYYY/MM/DD-HH:mm')}.csv`, transactions.export.data);
            exportTransactionsReset();
        }
    }, [transactions.export.success]); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Filters

    const onSiteFilterChange = (id: Site['id']) => {
        setSearchParam('site', id);
    };

    const onParkingFilterChange = (searchParking: string) => {
        setSearchParam('parkingName', searchParking);
    };

    // ---------------------------------------
    // Invoices

    const invoice = (id: Transaction['id']) => {
        getInvoice({id});
    };

    useEffect(() => {
        if (transactions.invoice.success && transactions.invoice.data) {
            const fileName = `receipt_${transactions.invoice.payload.id}.pdf`;
            downloadFile(fileName, transactions.invoice.data, 'application/pdf');
            getInvoiceReset();
        }

        if (transactions.invoice.error) {
            notification.error({
                message: 'Reçu',
                description: 'Une erreur est survenue lors de la récupération du reçu.',
            });
            getInvoiceReset();
        }
    }, [transactions.invoice.success, transactions.invoice.error]); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Table columns

    const columns: Array<ColumnProps<Transaction>> = [
        {
            dataIndex: 'createdAt',
            title: formatMessage(genericMessages.date),
            sorter: true,
            defaultSortOrder: 'descend',
            render: (date) => moment(date).format('DD/MM/YYYY - HH:mm'),
        },
        {
            dataIndex: 'titleId',
            title: formatMessage(genericMessages.ticket),
            ellipsis: true,
        },
        {
            title: formatMessage(genericMessages.site),
            key: 'site',
            dataIndex: 'site',
            ellipsis: true,
            render: (site) => site?.name ?? '-',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="filter-container">
                    <SiteFilterSelect
                        onChange={onSiteFilterChange}
                        size="middle"
                    />
                </div>
            ),
        },
        {
            dataIndex: 'zoneName',
            title: formatMessage(genericMessages.parking),
            key: 'zoneName',
            ellipsis: true,
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="filter-container">
                    <Search
                        placeholder={formatMessage(genericMessages.parkingSearch)}
                        onSearch={onParkingFilterChange}
                        loading={transactions.list.loading}
                        allowClear
                        size="middle"
                    />
                </div>
            ),
        },
        {
            title: formatMessage(genericMessages.stationnement),
            render: (transaction: Transaction) => (
                <>
                    {transaction.entryDateTime && (<div>{moment(transaction.entryDateTime).format('DD/MM/YYYY - HH:mm')}</div>)}
                    {transaction.exitDateTime && (<div>{moment(transaction.exitDateTime).format('DD/MM/YYYY - HH:mm')}</div>)}
                </>
            ),
        },
        {
            title: formatMessage(genericMessages.amount),
            key: 'totalDueAmount',
            dataIndex: 'totalDueAmount',
            sorter: true,
            render: (totalDueAmount, transaction) => (
                <Price value={transaction.totalDueAmount} currency={transaction.currencyIso} currencyCentsDigits={transaction.currencyCentsDigits} />
            ),
        },
        {
            dataIndex: 'status',
            title:  formatMessage(genericMessages.payment),
            render: (text, record) => (
                <TagTransactionStatus value={record.paymentStatus} />
            ),
            filterMultiple: false,
            filters: [
                {text: TranslateTransactionStatus(Status.Pending), value: Status.Pending},
                {text: TranslateTransactionStatus(Status.Cancelled), value: Status.Cancelled},
                {text: TranslateTransactionStatus(Status.Paid), value: Status.Paid},
                {text: TranslateTransactionStatus(Status.Unstarted), value: Status.Unstarted},
                {text: TranslateTransactionStatus(Status.Processing), value: Status.Processing},
                {text: TranslateTransactionStatus(Status.Rejected), value: Status.Rejected},
                {text: TranslateTransactionStatus(Status.InternalError), value: Status.InternalError},
                {text: TranslateTransactionStatus(Status.NothingToPay), value: Status.NothingToPay},
            ],
        },
        {
            title: formatMessage(genericMessages.actions),
            key: 'actions',
            fixed: 'right',
            width: 110,
            render: (text, record) => (
                <>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={edit.bind(null, record.id)}
                        shape="circle"
                    />
                    {record.paymentStatus === Status.Paid && (
                        <>
                            &nbsp;
                            &nbsp;
                            <Button
                                icon={<DownloadOutlined />}
                                onClick={invoice.bind(null, record.id)}
                                shape="circle"
                            />
                        </>
                    )}
                </>
            ),
        },

    ];

    return (
        <>
            <Seo title="Transactions" />

            <div className="page-header justify-start">
                <h1 className="page-title">
                   <FormattedMessage {...genericMessages.transactionsHistory}/>  <Badge count={transactions.list.data.totalCount} overflowCount={100000} />
                </h1>

                <Menu
                    mode="horizontal"
                    className="main-menu"
                    defaultSelectedKeys={[location.pathname]}
                    selectedKeys={[location.pathname]}
                >
                    <Menu.Item key={getRoute(RoutePathName.transactions)}>
                        <Link to={getRoute(RoutePathName.transactions)}>
                            <span> <FormattedMessage {...genericMessages.transactionsParkings}/></span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={getRoute(RoutePathName.subscriptions)}>
                        <Link to={getRoute(RoutePathName.subscriptions)}>
                            <span><FormattedMessage {...genericMessages.transactionsSubscriptions}/></span>
                        </Link>
                    </Menu.Item>
                </Menu>
            </div>

            <div className="page-search-filters">
                <Search
                    className="page-search"
                    placeholder={formatMessage(genericMessages.ticketSearch)}
                    defaultValue={siteQueryParam || ''}
                    loading={transactions.list.loading}
                    onSearch={onSearch}
                    allowClear
                    size="large"
                />

                <div className="page-filters">
                    <span><FormattedMessage id="parking.period" defaultMessage="Période de stationnement" /></span>
                    <RangePicker
                        onChange={onDateChange}
                        placeholder={[formatMessage(genericMessages.transactionInDate), formatMessage(genericMessages.transactionOutDate)]}
                        size="large"
                    />
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        onClick={onExport}
                    > <FormattedMessage {...genericMessages.exportData} />
                    </Button>
                </div>
            </div>

            {transactions.list ? (
                <Table<Transaction>
                    className="page-table"
                    rowKey={rowKey}
                    columns={columns}
                    loading={transactions.list.loading}
                    dataSource={transactions.list.data.items}
                    pagination={{
                        total: transactions.list.data.totalCount,
                        current: transactions.list.data.page + 1,
                        pageSize: transactions.list.data.pageSize,
                    }}

                    onChange={onTableChange}
                    scroll={{ x: 1400 }}
                />
            ) : undefined}

            <TransactionDrawer
                id={selectedId}
                isVisible={isDrawerVisible}
                onClose={onDrawserClose}
                onSuccess={onDrawerSuccess}
            />
        </>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    transactions: state.transactions,
});

export default connect(
    mapStateToProps,
    {
        getList: transactionsList.trigger,
        exportTransactions: transactionsExport.trigger,
        exportTransactionsReset: transactionsExport.reset,
        getInvoice: transactionsInvoice.trigger,
        getInvoiceReset: transactionsInvoice.reset,
    },
)(TransactionsList);
