import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRawRoute, RoutePathName } from './routes';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './pages/error';
import Login from './pages/login';
import Privacy from './pages/privacy';
import UsersList from './pages/users';
import TransactionsList from './pages/transactions';
import SitesList from './pages/sites';
import ResetPassword from './pages/resetPassword';

import './assets/styles/App.less';
import SubscriptionsList from './pages/subscriptions';

const App: FC = () => (
    <Switch>

        <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
        <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />
        <ProtectedRoute loginPath={getRawRoute(RoutePathName.login)}>
            <MainLayout>
                <ErrorBoundary>
                    <Switch>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.transactions)}
                        >
                            <TransactionsList />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.subscriptions)}
                        >
                            <SubscriptionsList />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.sites)}
                        >
                            <SitesList />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.users)}
                        >
                            <UsersList />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.privacy)}
                        >
                            <Privacy />
                        </Route>

                        <Route path="*">
                            <ErrorPage />
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </MainLayout>
        </ProtectedRoute>
    </Switch>
);

export default App;
