import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { FormattedMessage, useIntl } from 'react-intl';
import genericMessages from '../../locale/genericMessages';
import {
    Table,
    Button,
    Tag,
    Badge,
    Popconfirm,
    Tooltip,
} from 'antd';

import { User, Site, UserRole } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import Search from 'antd/lib/input/Search';
import UserDrawer from './UserDrawer';
import {
    UsersState,
    list as usersList,
    roles as usersRoles,
    remove as usersRemove,
} from '../../store/actions/users';

import { FilterQuery } from '../../store/api';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Seo from '../../components/Seo';
import SiteFilterSelect from '../sites/SiteFilterSelect';
import moment from 'moment';

const rowKey = (item: User) => `${item.id}`;

interface UsersListProps {
    users: UsersState;
    getList: typeof usersList.trigger;
    getRoles: typeof usersRoles.trigger;
    remove: typeof usersRemove.trigger;
    resetRemove: typeof usersRemove.reset;
}

const UsersList: FC<UsersListProps> = ({
    users,
    getList,
    getRoles,
    remove,
    resetRemove,
}) => {

    const itemsPerPage: number = 20;
    const { formatMessage } = useIntl();
    const [selectedId, setSelectedId] = useState<string | undefined>();
    // const [ search, setSearch ] = useState<string>();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [lastSearchParams, setLastSearchParams] = useState<{}>();

    useEffect(() => {
        setLastSearchParams({
            ...lastSearchParams,
            page: 0,
            pageSize: itemsPerPage,
        });
        getRoles();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (users.remove.success) {
            resetRemove();
            getList({ ...lastSearchParams });
        }
    }, [users.remove.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    useEffect(() => {
        if (lastSearchParams) { getList({ ...lastSearchParams }); }
    }, [lastSearchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const onTableChange: TableProps<User>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const filters: FilterQuery['filters'] = [];

        if (tableFilters.role) {
            filters.push({
                name: 'role',
                value: tableFilters.role,
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Drawer management

    const edit = (id: string) => {
        setSelectedId(id);
        setIsDrawerVisible(true);
    };

    const removeItem = (id: string) => {
        remove({ id });
    };

    const add = () => {
        setSelectedId(undefined);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = () => {
        setIsDrawerVisible(false);
    };

    const onDrawerSuccess = () => {
        getList({ ...lastSearchParams });
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Filters

    const onSiteFilterChange = (id: Site['id']) => {
        setSearchParam('site', id);
    };

    // ---------------------------------------
    // Table columns

    const columns: Array<ColumnProps<User>> = [
        {
            title: formatMessage(genericMessages.headerUsers),
            key: 'sites',
            dataIndex: 'sites',
            width: 240,
            ellipsis: true,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="filter-container">
                    <SiteFilterSelect
                        onChange={onSiteFilterChange}
                        size="middle"
                    />
                </div>
            ),
            render: (sites: Site[]) => {
                return (sites && sites.length > 0) ?
                    (
                        (sites.length === 1) ?
                            sites[0].name :
                            <Tooltip placement="topLeft" title={sites.map((s) => s.name).join(', ')}><u>Multiple</u></Tooltip>)
                    : 'N/A';
            },
        },
        {
            dataIndex: 'lastName',
            title: formatMessage(genericMessages.userLastName),
            sorter: true,
            ellipsis: true,
        },
        {
            dataIndex: 'firstName',
            title: formatMessage(genericMessages.userFirstName),
            sorter: true,
            ellipsis: true,
        },
        {
            dataIndex: 'email',
            title: formatMessage(genericMessages.userEmail),
            ellipsis: true,
        },
        {
            dataIndex: 'createdAt',
            title: formatMessage(genericMessages.userCreationDate),
            sorter: true,
            render: (date) => moment(date).format('DD/MM/YYYY'),
        },
        {
            dataIndex: 'role',
            key: 'role',
            title: formatMessage(genericMessages.userRole),
            render: (value) => {
                return (<Tag>{value === UserRole.Customer ? 'Client' : setFirstCharUpper((value as string))}</Tag>);
            },
            filterMultiple: false,
            filters: [
                { text: formatMessage(genericMessages.userRoleSuperAdmin), value: UserRole.SuperAdmin },
                { text: formatMessage(genericMessages.userRoleAdmin), value: UserRole.Admin },
                { text: formatMessage(genericMessages.userRoleSite), value: UserRole.Site },
                { text: formatMessage(genericMessages.userRoleCustomer), value: UserRole.Customer },
            ],
        },
        {
            title: formatMessage(genericMessages.actions),
            key: 'actions',
            fixed: 'right',
            width: 120,
            render: (text, record) => (
                <>
                    <Button
                        icon={<EditOutlined />}
                        onClick={edit.bind(null, record.id)}
                        shape="circle"
                    />
                    &nbsp;&nbsp;
                    {(!record.transactions || record.transactions.length) <= 0 && (
                    <Popconfirm
                        title={formatMessage(genericMessages.confirmDelete)}
                        onConfirm={removeItem.bind(null, record.id)}
                        okText={formatMessage(genericMessages.validate)}
                        cancelText={formatMessage(genericMessages.cancel)}
                        placement="topRight"
                    >
                        <Button
                            danger
                            icon={<DeleteOutlined />}
                            shape="circle"
                        />
                    </Popconfirm>
                    )}
                </>
            ),
        },

    ];

    return (
        <>
            <Seo title={formatMessage(genericMessages.headerUsers)}/>

            <div className="page-header">
                <h1 className="page-title">
                <FormattedMessage {...genericMessages.headerUsers}/> <Badge count={users.list.data.totalCount} overflowCount={100000} />
                </h1>

                <Button
                    type="primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={add}
                >
                    <FormattedMessage {...genericMessages.userCreate}/>
                </Button>
            </div>

            <Search
                className="page-search"
                placeholder={formatMessage(genericMessages.userSearch)}
                loading={users.list.loading}
                onSearch={onSearch}
                allowClear
                size="large"
            />

            {users.list ? (
                <Table<User>
                    className="page-table"
                    rowKey={rowKey}
                    columns={columns}
                    loading={users.list.loading}
                    dataSource={users.list.data.items}
                    pagination={{
                        total: users.list.data.totalCount,
                        current: users.list.data.page + 1,
                        pageSize: users.list.data.pageSize,
                    }}
                    onChange={onTableChange}
                    scroll={{ x: 1100 }}
                />
            ) : undefined}

            <UserDrawer
                id={selectedId}
                roles={users.roles.data.items}
                isVisible={isDrawerVisible}
                onClose={onDrawserClose}
                onSuccess={onDrawerSuccess}
            />
        </>
    );

};

const setFirstCharUpper = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const mapStateToProps = (state: MainReducerState) => ({
    users: state.users,
});

export default connect(
    mapStateToProps,
    {
        getList: usersList.trigger,
        getRoles: usersRoles.trigger,
        remove: usersRemove.trigger,
        resetRemove: usersRemove.reset,
    },
)(UsersList);
