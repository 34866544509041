export default Object.freeze({
    PAGE_SIZE: 50,
    CONTENT_HEADER_HEIGHT: 88,
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*{}[\]()\\\-_/'"`~,;:.<>])(.{10,})$/,
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Orbility',
        DEFAULT_TITLE: 'Orbility',
        DEFAULT_DESCRIPTION: 'Orbility',
    }),
    GOOGLE_ANALYTICS: '',
});
