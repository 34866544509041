import { User } from './apiTypes';
import { SearchPaginationQuery } from './';
import api, { cleanPayload } from './_client';

export interface UserDetailsPayload {
    id: string;
}

export type UserListPayload = SearchPaginationQuery;

export const details = (payload: UserDetailsPayload) => api.get(`/cms/users/${payload.id}`);

export const list = (payload: UserListPayload = {}) => {
    return api.get(`/cms/users`, {params: cleanPayload(payload)});
};

/*export const roles = (payload: UserListPayload = {}) => {
    return api.get(`/roles`, {params: payload});
};*/

export const create = (payload: User) => {
    return api.post(`/cms/users`, payload);
};

export const update = (payload: User) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/cms/users/${id}`, payload);
};

export const remove = (payload: User) => {
    return api.delete(`/cms/users/${payload.id}`);
};

export const resetPassword = (payload: User) => {
    return api.post(`/cms/users/${payload.id}/reset-password`);
};

export const roles = (payload: UserListPayload = {}) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                items: [
                    {
                        id: '1',
                        name: 'Super administrateur',
                    },
                    {
                        id: '2',
                        name: 'Site',
                    },
                ],
                totalCount: 2,
                page: 0,
                pageSize: 20,
                pageCount: 0,
            });
        }, 0);
    });

/*
export const list = (payload: UserListPayload = {}) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                items: [
                    {
                        id: 1337,
                        firstName: 'Jean-Michel',
                        lastName: 'Bécatresse',
                        email: 'jean-mi@ezeeworld.com',
                    },
                    {
                        id: 1338,
                        firstName: 'Eugene',
                        lastName: 'Compasse',
                        email: 'eugene@ezeeworld.com',
                    },
                ],
                totalCount: 2,
                page: 0,
                pageSize: 20,
                pageCount: 0,
            });
        }, Math.floor(1000 + Math.random() * 1000));
    });
*/
